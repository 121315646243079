.breadcrumb {
    text-align: center;

    @include formby() {
        margin-bottom: 75px;
    }

    &__list {
	    display: inline-block;
        text-align: left;
        margin: 0;
        padding: 15px 5px 20px 5px;
        list-style-type: none;

        li {
	        display: inline-block;
	        white-space: nowrap;

            &:nth-child(1n+3) {
                &:before {
                    content: '';
                    width: 8px;
                    height: 8px;
                    margin-right: 10px;
                    display: inline-block;
                    background: url(img/breadcrumb.svg) no-repeat center;
                    background-size: 4px 8px;
                }
            }

            @include ullensvang() {
                font-family: $ullensvang-secondary-font;
                text-transform: uppercase;
                font-size: 11px;
                letter-spacing: 0.1em;
                font-weight: 500;
                color: get-color('aqua');
            }

            @include staverton() {
                text-transform: uppercase;
                font-size: 11px;
                font-weight: 500;
                color: get-color('golden-brown');
            }

            @include formby() {
                text-transform: uppercase;
                font-size: 11px;
                font-weight: 500;
                letter-spacing: 0.1em;
                color: get-color('mid-warm-grey');
            }

            a {
                @include ullensvang() {
                    color: get-color('dark-blue');
                }

                @include staverton() {
                    color: get-color('pickled-blue');
                }

                @include formby() {
                    color: get-color('warm-grey');
                }
            }
        }
    }
}
