.carousel-nav {
	display: flex;
	justify-content: center;

	.slick-arrow {
		display: inline-block;
		min-width: 35px;
		width: 21px;
		height: 21px;
		text-indent: -12345px;
		cursor: pointer;
		transition: background-color 0.3s ease-in-out;
		background: no-repeat center center;

		&.slick-prev {
			order: 1;
			background-position: right center;
			background-image: url('img/carousel-prev-dark-blue.svg');

			@include staverton() {
				background-image: url('img/carousel-prev-pickled-blue.svg');
			}

			@include formby() {
				background-image: url('img/carousel-prev-warm-grey.svg');
			}

			&:hover,
			&:active {
				background-image: url('img/carousel-prev-aqua.svg');

				@include staverton() {
					background-image: url('img/carousel-prev-light-grey.svg');
				}
				
				@include formby() {
					background-image: url('img/carousel-prev-light-grey.svg');
				}
			}
		}

		&.slick-next {
			order: 3;
			background-position: left center;
			background-image: url('img/carousel-next-dark-blue.svg');

			@include staverton() {
				background-image: url('img/carousel-next-pickled-blue.svg');
			}

			@include formby() {
				background-image: url('img/carousel-next-warm-grey.svg');
			}

			&:hover,
			&:active {
				background-image: url('img/carousel-next-aqua.svg');

				@include staverton() {
					background-image: url('img/carousel-next-light-grey.svg');
				}
				
				@include formby() {
					background-image: url('img/carousel-next-light-grey.svg');
				}
			}
		}
	}

	.slick-dots {
		order: 2;
		margin: 0;
		display: inline-block;

		li {
			display: inline;
			margin: 0 3px;

			button {
				position: relative;
				width: 10px;
				height: 21px;
				text-indent: -12345px;
				cursor: pointer;
				width: 40px;

				@include breakpoint(large) {
					width: 84px;
				}

				&::after {
					content: '';
					position: absolute;
					top: 8px;
					left: 0;
					width: 100%;
					border-bottom: 1px solid;
					transition: all 0.3s ease-in-out;

					@include ullensvang() {
						border-color: get-color('aqua');
					}

					@include staverton() {
						border-color: get-color('castle-grey');
					}

					@include formby() {
						border-color: get-color('mid-warm-grey');
					}
				}
			}

			&.slick-active,
			&:hover {
				button::after {
					border-bottom-width: 2px;

					@include ullensvang() {
						border-color: get-color('dark-blue');
					}

					@include staverton() {
						border-color: get-color('pickled-blue');
					}

					@include staverton() {
						border-color: get-color('warm-grey');
					}
				}
			}
		}
	}
}
