.accommodation {
	&__item {
		position: relative;
		margin-bottom: 94px;

		&:last-child {
			margin-bottom: -32px;
		}

		@include breakpoint(small only) {
			margin-bottom: 20px;
			padding: 0 20px;
		}

		&::after {
			content: '';
			position: absolute;
			z-index: 15;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 6px;

			@include ullensvang() {
				background-color: get-color('pale-grey');
			}
		}
	}

	&__slider {
		position: relative;
		z-index: 5;
		overflow: hidden;

		&::before {
			z-index: 10;
		}

		.slick-list {
			overflow: visible;
		}

		.slick-slider,
		.slick-list,
		.slick-track,
		.slick-slide,
		.slick-slide > div,
		.accommodation__slider-slide {
			height: 100%;
			position: relative;
		}

		a {
			display: flex !important;
			height: 100%;

			img {
				display: inline !important;
				object-fit: cover;
				width: 100% !important;
				height: 100% !important;
			}
		}
	}

	&__content {
		background-color: #fff;
		position: relative;
		z-index: 10;
		@include flex-align-center();

		&-inner {
			padding: 65px 20px 30px;

			@include breakpoint(medium only) {
				padding: 65px 20px;
			}

			@include breakpoint(large) {
				padding: 10px 10% 100px;
			}
		}


		h2 {
			@include copy-headings();

			@include ullensvang() {
				color: get-color('dark-blue');
				margin-bottom: 4px;
			}

			@include staverton() {
				margin-bottom: 30px;

				@include breakpoint(small only) {
					margin-bottom: 15px;
				}
			}

			@include formby() {
				text-transform: uppercase;
				margin-bottom: 20px;
			}
		}

		h3 {
			@include sub-headings();

			@include ullensvang() {
				margin-bottom: 5px;
			}

			@include staverton() {
				margin-bottom: 35px;
			}

			@include formby() {
				text-transform: uppercase;
				margin-bottom: 50px;
			}
		}

		h2,
		h3 {
			a {
				color: inherit;
			}
		}

		p {
			@include body-copy();

			margin-left: auto;
			margin-right: auto;

			&:last-child {
				margin-bottom: 68px;
			}
		}



		&-price {

			@include staverton() {

				font-style: italic;
				color: get-color('golden-brown') !important;
				font-family: $staverton-primary-font !important;
				font-weight: 400;
				font-size: 25px !important;
				padding-top: 25px;

			}
			
			

		}

		.multiple-buttons {
			padding-top: 50px;

			@include staverton() {
				padding-top: 25px;
			}

			@include breakpoint(small only) {
				padding-top: 25px;

				.button {
					display: block;
					width: 195px;
					margin-left: auto !important;
					margin-right: auto !important;
					
					&:first-child:not(:last-child) {
						margin-bottom: 20px;
					}
				}
			}
		}

		.carousel-nav {
			position: absolute;
			bottom: 45px;
			left: 0;
			width: 100%;

			@include breakpoint(small only) {
				bottom: initial;
				top: 20px;
			}

			@include breakpoint(medium only) {
				bottom: 32px;
			}
		}
	}

	&__item {
		@include breakpoint(medium) {
			&:nth-child(odd) {
				.accommodation__slider {
					order: 2;
					padding-right: 50px;

					a {
						padding-right: 43px;

						.img-credit {
							right: 59px;
						}
					}
				}

				.accommodation__content {
					order: 1;
				}
			}

			&:nth-child(even) {
				.accommodation__slider {
					padding-left: 50px;

					a {
						padding-left: 43px;
					}
				}
			}
		}
	}
}

.multiple-icons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 50px;

	.caption {
		font-size: 14px;
		transform: translateY(10px);
	}

	svg {
		fill: #4091a4;
		max-height: 26px;
	}
}

.theme-staverton {

	.multiple-icons {

		.caption {
			color: #4e5a5f;
		}

		svg {
			fill: #4e5a5f !important;
		}

	}

}