body {

	&.theme-ullensvang {
		font-family: $ullensvang-secondary-font;
		color: get-color('dark-grey');
		background-color: get-color('pale-grey');
	}

	&.theme-staverton {
		font-family: $staverton-secondary-font;
		color: get-color('pickled-blue');
		background: rgb(245,241,233);
		background: linear-gradient(135deg, rgba(245,241,233,1) 0%, rgba(0,0,0,0) 100%);
	}

	&.theme-formby {
		font-family: $formby-font;
		color: get-color('warm-grey');
	}
}
