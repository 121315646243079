.notice-banner {
    background-color: get-color('aqua');
    position: relative;
    z-index: 999999;
    padding: 20px 53px;
    padding-right: 150px;
    transform: translateY(0);
    transition: all 0.5 ease-in-out;
  

    &.hide {
        transform: translateY(-100%);
        transition: all 0.5 ease-in-out;
    }

    @media screen and (max-width: $off-canvas-breakpoint) {
        padding: 20px;
    }

    &__content {
        p {
            color: #fff;
            font-weight: 600;
            font-size: 18px !important;
            display: inline-block;
            margin-right: 20px;

            @media screen and (max-width: $off-canvas-breakpoint) {
                display: block;
                margin-right: 0;
                margin-bottom: 15px;
            }
        }

        a.button {
            display: inline-block !important;
            background-color: #fff;
            color: get-color('dark-blue');
            padding: 6px 16px!important;

            &:hover {
                background-color:get-color('dark-blue');
                color: #fff;
            }
        }


    }
    &__close {
		position: absolute;
		top: 50%;
        transform: translateY(-50%);
		right: 53px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		width: 53px;
		@include body-copy();
		font-weight: bold !important;
		font-size: 18px;
		line-height: 18px !important;
		color: #fff !important;
        width: 80px;
        display: flex;

        span {
            display: block;
          
        }

        @media screen and (max-width: $off-canvas-breakpoint) {
            top: unset;
            bottom: 0px;
            right: 20px;
        }

       
		
		@include ullensvang() {
			text-transform: lowercase;
		}
		
		rect {
			transform-origin: center;
			transition: all 0.3s ease-in-out;

			&#burger-line-top {
				transform: rotate(45deg) translate(0, -4px);
			}
			
			
			&#burger-line-middle {
				transform: rotate(45deg) translate(-7px,-9px);
			}
			
			&#burger-line-bottom {
				opacity: 0;
			}
			
			@include ullensvang() {
				fill: #fff;
			}

			@include staverton() {
				fill: #fff;
			}

			@include formby() {
				fill: #fff;
			}
		}
	}

}