.pagination {
    margin: 0 auto 60px;
    padding: 30px 0;

    @include breakpoint(small only) {
        margin-bottom: 40px;
        padding: 0 20px;
    }

    &__inner {}

    &__prev,
    &__next {
        display: inline-block;

        a {
            position: relative;
            font-family: $ullensvang-primary-font;
            font-size: 25px;
            line-height: 40px;
            color: get-color('dark-grey');

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                width: 7px;
                height: 14px;
                transform: translateY(-50%);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 7px 14px;
            }

            &:hover,
            &:active {
                background-color: transparent;
            }

            @include staverton() {
              font-family: $staverton-primary-font;
              font-size: 20px;
              color: get-color('pickled-blue');
            }

            @include formby() {
                font-size: 15px;
                font-wight: 400;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: get-color('warm-grey');
            }
        }
    }

    &__prev {
        text-align: left;

        a {
            padding-left: 20px;

            &::before {
                left: 0;
                background-image: url('img/arrow-left-dark-blue.svg');

                @include staverton() {
                  background-image: url('img/staverton/arrow-left-pickled-blue.svg');
                }

                @include formby() {
                    background-image: url('img/formby/arrow-left-warm-grey.svg');
                  }
            }
        }
    }

    &__next {
        text-align: right;

        a {
            padding-right: 20px;

            &::before {
                right: 0;
                background-image: url('img/arrow-right-dark-blue.svg');

                @include staverton() {
                  background-image: url('img/staverton/arrow-right-pickled-blue.svg');
                }

                @include formby() {
                    background-image: url('img/formby/arrow-right-warm-grey.svg');
                  }
            }
        }
    }
}
