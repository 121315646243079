.offers {
	margin-bottom: 30px;

    @include breakpoint(small only) {
        padding: 0 10px 10px;
    }

    @include formby() {
        padding-top: 50px;
    }

	&__image {
		img {
			width: 100%;
			display: block;
		}
	}

	&__item {
        background-color: #fff;
        margin: 0 0 75px auto;
        padding: 0;

        @include breakpoint(small only) {
            margin-bottom: 30px;
        }

        &-image {

        }

        &-content {
            padding-left: 85px;

            @include breakpoint(small only) {
                padding: 25px;
            }


            &-info {
                text-align: left;

                @include breakpoint(small only) {
                    text-align: center;
                    margin-bottom: 30px;
                }

                @include breakpoint(medium only) {
                    margin-bottom: 30px;
                }

                h2 {
                    @include copy-headings;
                    margin-bottom: 0;

                    a {
                        @include copy-headings;
                    }

                    @include staverton() {
                        margin-bottom: 18px;

                        @include breakpoint(small only) {
                            margin-bottom: 12px;
                        }
                    }

                    @include formby() {
                        text-transform: uppercase;
                        margin-bottom: 20px;
                    }
                }

                h3 {
                    @include copy-headings;
										@include price;
                    margin-bottom: 34px;

                    @include breakpoint(small only) {
                        margin-bottom: 30px;
                    }

                    @include formby() {
                        margin-bottom: 50px;
                    }

                }

                ul {
                    list-style: none;
                    margin: 0;

                    li {
                        @include bullet-list;
                        padding-left: 10px;
                        padding-right: 10px;
                        position: relative;

                        @include breakpoint(small only) {
                            text-align: left;
                        }

                        &::before {
                            content: '-';
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }

            &-cta {
                display: flex;
                align-items: self-end;

                @include breakpoint(small only) {
                    text-align: left;
                }

                @include breakpoint(medium only) {
                    .multiple-buttons {
                        text-align: left;
                    }
                }

                .button {
                    margin: 0 0 20px !important;

                    @include breakpoint(small only) {
                        display: block;
                        width: 180px;
                    }
                }
            }
        }

        &:nth-of-type(odd) {
            margin: 0 auto 75px 0;

            @include breakpoint(large) {
                width: calc(100% - 75px);
            }

            @include breakpoint(small only) {
                margin-bottom: 30px;
            }

            .offers__item-image {
                order: 2;

                @include breakpoint(small only) {
                    order: 1;
                }
            }

            .offers__item-content {
                order: 1;

                @include breakpoint(small only) {
                    order: 2;
                }
            }
        }

        &:nth-of-type(even) {
            @include breakpoint(large) {
                width: calc(100% - 75px);
                margin-left: 75px;
            }
        }
	}
}
