.reveal {
	z-index: 50000;
	background-color: rgba(#fff, 0.89);
	backdrop-filter: blur(2px);
	display: block;

	&-overlay {
		padding: 50px;
	}

	&.full {
		top: 0px !important;
		overflow: scroll;
		position: absolute;
		max-width: 100vw;
	}
}