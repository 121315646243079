.social {
	padding-top: 110px;
	padding-bottom: 15px;

	@include staverton() {
		padding-bottom: 25px;

		@include breakpoint(small only) {
			padding-bottom: 15px;
		}
	}

	@include breakpoint(small only) {
		padding-top: 85px;
	}

	&__header {
		text-align: center;

		h2 {
			@include copy-headings();
			font-size: 50px !important;
			margin-bottom: 0;

			@include ullensvang() {
				padding-bottom: 20px;
				
				&:has(+ h3) {
					padding-bottom: 0;
				}
			}

			@include staverton() {
				font-size: 42px !important;
				margin-bottom: 10px;
			}

			@include formby() {
				font-size: 25px !important;
				line-height: 37px;
				letter-spacing: 0.05em;
				color: get-color('mid-warm-grey');
				margin-bottom: 20px;
				text-transform: uppercase;
			}
		}

		h3 {
			@include sub-headings();
			font-size: 16px !important;
			margin-bottom: 15px;

			@include ullensvang() {
				font-family: $ullensvang-secondary-font;
				font-weight: 500;
				letter-spacing: 0.075em;
				color: get-color('aqua');
			}

			@include staverton() {
				font-weight: 700;
				font-size: 12px !important;
				margin-bottom: 45px;

				@include breakpoint(small only) {
					margin-bottom: 38px;
				}
			}

			@include formby() {
				font-size: 12px !important;
				line-height: 19px;
				font-weight: 700;
				letter-spacing: 0.1em;
				color: get-color('warm-grey');
				text-transform: uppercase;
				margin-bottom: 25px;
			}

			a {
				color: inherit;
			}
		}
	}

	&__feed {
		@include breakpoint(medium) {
			padding: 0 25px;
		}

		.crt-grid-post {
			margin-top: -25px;

			@include breakpoint(medium) {
				padding: 0 12px;
			}
		}
	}

	.crt-widget .crt-social-icon svg {
		color: #fff;
	}
}
