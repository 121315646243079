.noslider {
	padding-top: 200px;
}

main {
	position: relative;

	@at-root body.template-transparent-gradient.theme-ullensvang #{&} {
		&::before {
			content: '';
			position: absolute;
			top: 10px;
			left: 10px;
			right: 10px;
			bottom: 400px;
			z-index: -1;
			background: rgb(255,255,255);
			background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 100%);

			@include breakpoint(medium) {
				left: 40px;
				right: 40px;
			}

			@include breakpoint(medium) {
				left: 60px;
				right: 60px;
			}
		}
	}
}

.grid-container {
	max-width: none;
	margin-left: 10px;
	margin-right: 10px;
	padding: 0;

	@include breakpoint(medium) {
		margin-left: 20px;
		margin-right: 20px;
	}

	@include breakpoint(large) {
		margin-left: 60px;
		margin-right: 60px;
	}
}
