.detail-gallery {
	@include breakpoint(large) {
		padding: 10px 76px 0;
	}
	
	&::before {
		z-index: 1;
	}
	
	> .cell {
		position: relative;
		
		&::before {
			z-index: 20;
		}
	}
	
	.carousel-nav {
		padding: 50px 0 75px;
		text-align: center;
	}
}