.popup {
    width: 100%;
    padding: 10%;

    @include breakpoint(small only) {
        padding: 20px;
    }

    &__close {
        position: absolute;
        top: 2.5%;
        right: 2.5%;
        text-align: left;
        cursor: pointer;

        @include breakpoint(small only) {
            top: 5px;
            right: 5px;
        }
        
        span {
            display:  block;
            
            @include ullensvang() {
                font-family: $ullensvang-primary-font;
                font-size: 23px;
                font-weight: bold;
                text-transform: lowercase;
                color: get-color('dark-blue');
            }

            @include staverton() {
                font-family: $staverton-primary-font;
                font-size: 23px;
                text-transform: lowercase;
                color: get-color('pickled-blue');
            }

            @include formby() {
                font-family: $formby-font;
                font-size: 15px;
                text-transform: uppercase;
                font-weight: 700;
                color: get-color('warm-grey');
            }

            @include breakpoint(small only) {
                display: none;
            }
        }
        
        rect {
            transform-origin: left center;
            transition: all 0.3s ease-in-out;

            &#burger-line-top {
                transform: rotate(45deg) translate(0, -4px);
            }
            
            
            &#burger-line-middle {
                transform: rotate(45deg) translate(-7px,-9px);
            }
            
            &#burger-line-bottom {
                opacity: 0;
            }
            
            @include ullensvang() {
                fill: get-color('dark-blue');
            }

            @include staverton() {
                fill: get-color('pickled-blue');
            }

            @include formby() {
                fill: get-color('warm-grey');
            }
        }
    }

    &__wrap {
        background-color: #fff;
        padding: 35px 28px;
        max-width: 1037px;
        margin: auto;
        
        @include breakpoint(small only) {
            margin-top: 48px;
        }
    }

    &__text {
        @include flex-align-center();

        h2 {
            @include copy-headings;
            margin-bottom: 3px;

            @include staverton {
                margin-bottom: 15px;
            }
        }

        h3 {
            @include sub-headings;
            margin-bottom: 33px;
        }

        p {
            @include body-copy;
            max-width: 315px;
            margin-left: auto;
            margin-right: auto;
            
            &:last-of-type {
                margin-bottom: 25px;
                
                @include breakpoint(medium) {
                    margin-bottom: 46px;
                }
            }
        }
        
        .button {
            @include breakpoint(medium down) {
                margin-bottom: 30px !important;
            }
        }
    }

    &__image {
        position: relative;

        @include breakpoint(small only) {
            margin-bottom: 20px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            
            @include breakpoint(medium only) {
                max-width: 468px;
                margin: 0 auto 30px;
            }
        }
    }
    
    
    
    
    
    
    &--floorplan {
        padding: 100px 20px 20px;
        
        .popup {
            &__wrap {
                max-width: none;
            }
        }
    }
}