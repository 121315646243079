.other-rooms {
	text-align: center;

	&.grid-container {
		margin: 0 auto 100px;
		max-width: 1366px;

		@include breakpoint(small only) {
			padding-left: 20px;
			padding-right: 20px;
			margin-bottom: 25px;
		}
	}

	&__header {
		h2 {
			@include copy-headings();

			@include staverton() {
				margin-bottom: 50px;

				@include breakpoint(small only) {
					margin-bottom: 20px;
				}
			}
		}
	}

	&__room {
		transition: all 0.3s ease-in-out;
	}

	&__image {
		margin-bottom: 25px;

		img {
			transition: all 0.3s ease-in-out;
		}
	}

	&__content {
		h3 {
			@include similar-things-to-do();

			@include ullensvang() {
				font-size: 45px;
				line-height: 45px;
			}

			@include staverton() {
				margin-bottom: 20px;
			}

			a {
				color: inherit;
			}
		}

		p {
			@include sub-headings();
			opacity: 0;
			transition: all 0.3s ease-in-out;

			@include ullensvang() {
				margin-bottom: 55px;
			}

			@include staverton() {
				margin-bottom: 30px;
			}

		}
	}

	&__carousel.one-room {
		max-width: 400px;
		margin: auto;
		
		p {
			opacity: 1;
		}
	}

	&__carousel.two-rooms {
		max-width: 750px;
		margin: auto;
		
		p {
			opacity: 1;
		}
		
		.other-rooms {
			&__room {
				padding: 0 20px;
			}
		}
	}
	
	&__carousel.three-rooms {
		max-width: 1260px;
		margin: auto;
		
		p {
			opacity: 1;
		}
		
		.other-rooms {
			&__room {
				padding: 0 20px;
			}
		}
	}

	&__carousel.center-mode {
		.other-rooms {
			&__room {
				@include breakpoint(large) {
					//padding-top: 75px;
					padding-left: 50px;
					padding-right: 50px;

					img {
						margin-top: 32%;
					}
				}
			}

			&__content .multiple-buttons {
				@include breakpoint(large) {
					display: none;
				}
			}

			&__rate {
				@include breakpoint(large) {
					display: none;
				}
			}
		}

		.slick-current .other-rooms {
			&__room {
				@include breakpoint(large) {
					//padding-top: 0;
					padding-left: 0;
					padding-right: 0;

					img {
						margin-top: 0;
					}
				}
			}

			&__content {
				p {
					opacity: 1;
				}

				.multiple-buttons {

					@include staverton() {
						margin-bottom: 30px;
					}
					@include breakpoint(large) {
						display: block;
					}
				}
			}

			&__rate {
				@include breakpoint(large) {
					display: block;
				}
			}
		}
	}
}
