.home {
	position: relative;
	overflow: hidden;
	padding-top: 25px;

	@include ullensvang() {

		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: 100%;
			height: 145vh;
			background: linear-gradient(to bottom, rgba(64,145,164,0.4) 0%,rgba(245,245,245,1) 100%);
		}

		.intro:first-child {
			background-color: transparent;
			padding-top: 25px;
		}
	}

	h2 {
		@include copy-headings();

		@include ullensvang() {
			color: get-color('dark-blue');
			margin-bottom: 2px;
		}

		@include staverton() {
			margin-bottom: 30px;

			@include breakpoint(small only) {
				margin-bottom: 15px;
			}
		}

		@include formby() {
			color: get-color('warm-grey');
			text-transform: uppercase;
		}
	}
	
	h3 {
		@include sub-headings();
		
		@include ullensvang() {
			margin-bottom: 33px;
			
			@include breakpoint(small only) {
				margin-bottom: 8px;
			}
		}
		
		@include staverton() {
			margin-bottom: 35px;
		}
		
		@include formby() {
			color: get-color('warm-grey');
			text-transform: uppercase;
		}
	}

	h2,
	h3 {
		a {
			color: inherit;
		}
	}

	p {
		@include body-copy();

		margin-left: auto;
		margin-right: auto;

		&:last-child {
			margin-bottom: 20px;
		}

		@include formby() {
			color: get-color('warm-grey');
		}
	}

	.button {
		margin-top: 25px !important;
		margin-bottom: 50px !important;

		// @include staverton() {
		//
		// 	margin-top: 65px !important;
		//
		// 	@include breakpoint(small only) {
		// 		margin-top: 20px !important;
		// 	}
		// }
	}


	&__large-carousel,
	&__small-carousel {
		p {
			max-width: 310px;
		}

		h3 {
			max-width: 400px;
			margin: 0 auto;
		}
	}



	&__large-carousel {
		@include breakpoint(medium) {
			margin-top: 50px;
		}

		&-item {
			@include breakpoint(medium) {
				position: relative;
				padding-top: 115px;

				.home__large-carousel-big-image .img-credit {
					right: 36%;
					bottom: 24px;
				}
			}
		}

		&-big-image {
			@include breakpoint(medium) {
				position: absolute;
				z-index: 1;
				top: 0;
				right: 50%;
				transform: translate(33.333%, -25px);
				max-width: 840px;
			}
		}

		&-content {
			padding: 35px 0;
			background-color: #fff;

			@include breakpoint(medium) {
				position: relative;
				z-index: 2;
				padding: 58px 20px 0 8.333%;
			}

			&-inner {
				width: 100%;
				max-width: 471px;
				text-align: center;

				@include breakpoint(small only) {
					padding: 0 20px;
					max-width: none;
				}

				.img-credit {
					bottom: 31px;
				}
			}

			.reveal-image {
				display: block;
				padding-bottom: 15px;
			}
		}

		.carousel-nav-wrapper {
			background-color: #fff;
			padding: 0 0 45px;

			@include breakpoint(medium) {
				padding: 35px 0 45px 20px;
			}
		}
	}



	&__small-carousel {
		background-color: #fff;
		position: relative;

		@include breakpoint(small only) {
			margin-bottom: 80px;
		}

		&::before {
			@include breakpoint(medium) {
				content: '';
				position: absolute;
				top: -15px;
				right: 0;
				width: calc(50% - 35px);
				height: 15px;
				background-color: #f5f5f5;

				@include staverton() {
					content: none;
				}
			}
		}

		&-image {
			@include breakpoint(small) {

			}

			@include breakpoint(medium) {
				min-height: 760px;
				display: flex;
				position: relative;

				a {
					display: flex;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}


		}

		&-content {
			&-inner {
				text-align: center;
				padding-top: 58px;
			}
		}

		.carousel-nav {
			@include breakpoint(small only) {
				margin-bottom: 50px;
			}

			@include breakpoint(medium) {
				transform: translateY(-200px);
			}
		}
	}


	&__location {
		background-color: #fff;

		&-map {
			position: relative;
			min-height: 335px;

			@include breakpoint(medium) {
				min-height: 675px;
			}

			&::before {
				z-index: 10;
			}

			.map-container {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;

				@include breakpoint(medium) {
					height: calc(100% + 85px);
				}
			}
		}

		&-content {
			@include flex-align-center();


			@include breakpoint(small only) {
				padding: 32px 20px 50px;
			}

			@include breakpoint(medium) {
				padding: 30px 4.15%;
			}

			@include breakpoint(large) {
				padding: 30px 8.333%;
			}
			
			h2 {

				@include formby() {
					margin-bottom: 25px;
				}
			}

			h3 {
				@include ullensvang() {
					font-size: 40px;
					line-height: 1;
				}

				@include formby() {
					margin-bottom: 25px;
				}
			}

			.button--primary {
				padding: 6px 20px !important;
				margin: 0 auto 30px !important;
			}

			&-item {
				/*@include breakpoint(medium) {
					max-width: 360px;
				}*/


				@include staverton() {
					a {
						color: get-color('pickled-blue');
					}
				}

				h4 {
					@include ullensvang() {
						font-family: $ullensvang-primary-font;
						font-size: 30px;
						color: get-color('aqua');
						margin-bottom: 0;
					}

					@include staverton() {
						font-family: $staverton-primary-font;
						color: get-color('pickled-blue');
						font-size: 30px;
					}

					@include formby() {
						font-family: $formby-font;
						color: get-color('warm-grey');
						font-size: 30px;
						font-weight: 300;
					}
				}

				p {
					@include body-copy;
				}

				ul {
					li {
						@include body-copy;

						a {
							@include ullensvang() {
								color: get-color('dark-grey');
							}
						}
					}
				}
			}
		}
	}
}
