.offCanvas-newsletter {
    position: fixed;
    bottom: 0;
    left:0;
    width: 100%;
    height: 100%;
    max-height: 350px;
    z-index: 999999999999;
    background-color: get-color('eggs-hell');
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(100%);
    transition: all 300ms ease-in-out;

     @include breakpoint(small only) {

                max-height: unset;
                height: 100%;
                padding-top: 100px;
                padding-bottom: 50px;
                overflow-y: auto;
            }


    &.on {
        transform: translateY(0);

        &::before {
            content: '';
            width: 100%;
            height: 50%;
            position: absolute;
            top:-50%;
            left: 0;
            background: rgb(255,255,255);
            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.3) 100%);
        }
    }



   .grid-container {
       padding: 0 10px;

       @include breakpoint(small only) {
        padding: 0;
        }
   }

    &__close {
        position: absolute;
        top: 25px;
        left:25px;
        text-indent: -123456px;
        width: 25px;
        height: 25px;
        background-position: center center;
		background-image: url('img/staverton/circle-close-btn.svg');
        background-size: 25px 25px;
        cursor: pointer;

        @include breakpoint(small only) {
            left: unset;
            right: 25px;
            }
    }

    &__header {

        text-align: left;
        padding: 15px;

        @include breakpoint (small only) {
            padding: 0 25px;
        }

        h2 {
            font-family: $staverton-primary-font;
        }
    }



    .form {
        padding-left: 25px;

        @include breakpoint (small only) {
            padding: 0 25px;
        }

        input[type="text"],
		input[type="email"] {

            // @include breakpoint(medium) {
            //     width: calc(100% - 25px);
            // }

            @include staverton() {
                color: get-color('pickled-blue');
                background-color: #fff;
                width: 100%;
            }


		}
        &__checkbox input[type="checkbox"] {
            ~ label {

                @include staverton() {
                    text-transform: none;
                    &::before{
                        background-color: #fff;
                    }
                }
            }


        }

        .dob-section {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 25px;
            flex-wrap: wrap;

            &__inner {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                height: 33px;
                width: 70%;

                @media only screen and (max-width: 968px) {
                    width: 100%;
                }
            }

            label, p {
                font-size: 12px;
                text-transform: uppercase;
                line-height: 35px;
                vertical-align: middle;
				letter-spacing: 0.1em;
				opacity: 1;
				font-weight: 500;
                padding-left: 10px;
                margin-right: 20px;
            }

            .doblabel {
                display: block;
                height: 33px;
                margin: 0;
                padding-right: 15px;
                width: 30%;
                @media only screen and (max-width: 968px) {
                    width: 100%;
                }
            }

            .selectric-wrapper {
                background-color: #fff;
                height: 100%;
                top: 0;

                &.selectric-day-dob, &.selectric-month-dob {
                    margin-right: 15px;
                    width: 25%;
                    min-width: 70px;
                    padding-right: 5px;

                    @media only screen and (max-width: 968px) {
                        width: 33.3333%;
                    }
                }
                &.selectric-year-dob {
                    width: 50%;
                   
                    padding-right: 5px;

                    @media only screen and (max-width: 968px) {
                        width: 33.3333%;
                    }
                }
            }


            .selectric .label {
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                opacity: 1;
                font-weight: 500;
                padding-left: 10px;
                opacity: 0.8;

                @include breakpoint (small only) {
                    padding-left: 0;
                }
                
            }


        }

        &__checkbox {
            margin: 15px 0.78125rem 0;
        }

        &__submit {
            margin-top: 20px;

            .button--primary {
                width: 100%;
                min-width: 150px;
                max-width: max-content;
                background-color: get-color('pickled-blue');
				color: #fff;
                font-weight: 400;

                &:hover {
                    background-color: get-color('orange');
                }
            }
        }
    }

}

// .doblabel {
//   @include breakpoint(small only) {
//       display: block;
//       width: 100%;
//   }
// }

.postcode input {
  @include breakpoint(small only) {
  margin-bottom: 10px!important;
  }
}

// .offCanvas-newsletter .form__checkbox {
//   @include breakpoint(small only) {
//   clear: both;
//   display: block;
//   margin: 20px 0 0 10px;
//   padding: 10px 0 0 0;
//   }
// }
