.room-details {


    &__icons {
        text-align: center;
        max-width: 730px;
        margin: 25px auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 0 !important;

        .icon-wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 60px;
            margin-bottom: 10px;
        }

        h2 {
            width: 100%;
            @include similar-things-to-do();
			margin-bottom: 30px;

			@include formby() {
				color: get-color('mid-warm-grey');
				text-transform: uppercase;
			}
        }

        &-item {
            padding: 0 !important;
            width: 100px;
            margin: 10px;

            @include breakpoint (small only) {
                width: unset;
            }


            p {
                font-size: 14px !important;
                line-height: normal !important;

            }

            @include breakpoint (medium up) {
                /*bcursor: pointer; */

                &:hover svg path {
                    @include ullensvang() {
                        fill: get-color('dark-blue');
                        stroke: get-color('dark-blue');
                    }
                }
            }

            svg path {
                transition: all 0.3 ease-in-out;

                @include ullensvang() {
                    fill: get-color('aqua');
                    stroke: get-color('aqua');
                }

                @include formby() {
                    fill: get-color('warm-grey');
                    stroke: get-color('warm-grey');
                }

                @include staverton() {
                    fill: get-color('pickled-blue');
                    stroke: get-color('pickled-blue');
                }
            }
        }
    }

}
