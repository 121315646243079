.datepicker {
	&-container {
		position: fixed;
		z-index: 100000 !important;
		top: initial !important;
		bottom: 69px;
		transform: translateX(-1px);

		@include ullensvang() {
			font-family: $ullensvang-secondary-font;
			font-weight: 300;
		}
		
		@include formby() {
			background-color: get-color('warm-grey');
		}

		&::before,
		&::after {
			display: none;
		}
	}

	&-dropdown {
		border: 0;
		padding: 15px 18px 9px;
		box-shadow: none;

		@include ullensvang() {
			background: get-color('aqua');
		}

		@include staverton() {
			background: get-color('pickled-blue');
		}
	}

	&-panel {
		> ul {
			&[data-view="days"] {
				width: 100%;
				border: solid #fff;
				border-width: 1px 0 0 1px;
			}

			&[data-view="week"] {
				background-color: transparent;

				> li,
				> li:hover {
					background-color: transparent;
				}
			}

			> li {
				background-color: transparent;
				color: #fff;

				&:hover {
					background-color: transparent;
				}

				&.disabled,
				&.disabled:hover {
					background-color: transparent;
					opacity: 0.7;

					@include ullensvang() {
						color: get-color('dark-blue');
					}


				}

				&[data-view="week"] {
					background-color: transparent;
				}

				&[data-view="month prev"],
				&[data-view="month next"] {
					transform: translateY(-4px);
				}

				&[data-view="month current"] {
					font-size: 15px;
				}

				&[data-view="day"] {
					transition: all 0.3s ease-in-out;
				}

				&[data-view="day"],
				&[data-view="day disabled"],
				&[data-view="day prev"],
				&[data-view="day next"] {
					border: solid #fff;
					border-width: 0 1px 1px 0;
					box-sizing: border-box;
				}

				&.highlighted {
					font-weight: bold;
					background-color: transparent;
				}

				&:hover,
				&.picked {
					@include ullensvang() {
						background-color: #fff;
						color: get-color('dark-blue');
					}

					@include staverton() {
						background-color: get-color('golden-brown');
						color: #fff;
					}
				}
			}
		}
	}
}
