.small-meetings-widget {
	text-align: center;
	padding: 40px 20px;
	
	h2 {
		@include sub-headings();
	}
	
	&__holder {
		width: 100%;
		max-width: 640px;
		margin: 0 auto;
	}
	
	#tvd-widget-availability-btnSearch {
		width: 190px;
		
		@include ullensvang() {
			margin: 0;
			padding: 15px 35px;
			font-family: $ullensvang-primary-font;
			font-weight: bold;
			font-size: 18px;
			letter-spacing: 0.025em;
			text-transform: lowercase;
			color: #fff;
			background-color: get-color('dark-blue');
		
			&:hover,
			&:active {
				color: get-color('dark-blue');
				background-color: get-color('pale-aqua');
			}
		}
		
		@include staverton() {
			font-family: $staverton-primary-font;
			font-size: 21px;
			line-height: 19px;
			letter-spacing: 0.025em;
			background-color: get-color('eggs-hell');
			color: get-color('pickled-blue');
			padding: 14px 20px;
		
			&:hover {
				background-color: get-color('black-blue');
				color: #fff;
			}
		}
		
		@include formby() {
			font-family: $formby-font;
			font-size: 12px;
			line-height: 19px;
			letter-spacing: 0.1em;
			font-weight: 500;
			background-color: transparent;
			border: 1px solid get-color('mid-warm-grey');
			color: get-color('warm-grey');
			text-transform: uppercase;
			padding: 14px 20px;
			min-width: 125px;
		
			&:hover {
				background-color: get-color('warm-grey');
				color: #fff;
			}
		}
	}
}


.pika-lendar .is-selected button.pika-button,
.pika-lendar .has-event button.pika-button {
	box-shadow: none !important;
	
	@include ullensvang() {
		background-color: get-color('dark-blue') !important;
	}
	
	@include staverton() {
		background-color: get-color('golden-brown') !important;
	}
	
	@include formby() {
		background-color: get-color('warm-grey') !important;
	}
}

.pika-lendar button.pika-button {	
	&:hover,
	&:active {
		@include ullensvang() {
			background-color: get-color('aqua') !important;
		}
		
		@include staverton() {
			background-color: get-color('pickled-blue') !important;
		}
		
		@include formby() {
			background-color: get-color('cold-grey') !important;
		}
	}
}