.contact {
    padding-bottom: 60px;

    @include ullensvang() {
        background-color: #fff;
    }

    @include formby() {
        padding-top: 75px;
    }


    &__wrap {

    }

    &__details {
        @include breakpoint(small only) {
            text-align: center;
            margin-bottom: 60px;
        }

        h4 {
            @include sub-headings;

            @include formby() {
                text-transform: uppercase;
                font-weight: 300;
            }
        }

        p {
            @include body-copy;

            strong {
                font-weight: 500;
            }
        }

        @include staverton() {
          a,
          a:focus,
          a:hover {
          	color: get-color('pickled-blue');
           }
        }
    }

    &__form {

        &-field {

            .form__checkbox {
                margin-bottom: 30px;
            }
        }
        .form {
            
            .selectric {
                &::after {
                    right: 20px;
                }
                
                .label {
                    background-color: get-color('light-grey');
                    font-size: 11px;
                    font-weight: 500;
                    letter-spacing: 0.1em;
                    color: get-color('warm-grey');
                    text-transform: uppercase;
                    margin: 0 0 40px;
                    padding: 0 10px;
                }
            }

            input[type="text"], input[type="email"], input[type="tel"], textarea {
                background-color: get-color('light-grey') !important;
                font-size: 11px;
                font-weight: 500;
                letter-spacing: 0.1em;
                color: get-color('warm-grey');

                &::placeholder {
                    font-size: 11px;
                    font-weight: 500;
                    letter-spacing: 0.1em;
                    color: get-color('warm-grey');
                }
            }

            input[type="checkbox"] {
    
                ~ label {
    
                    &::before {
                        @include formby() {
                            background-color: get-color('light-grey');
                        }
                    }
                }
            }
            button[type="submit"],
            input[type="submit"] {
                @include formby() {
                    background-color: #fff;
                    color: get-color('warm-grey');
    
                    &:hover,
                    &:active {
                        background-color: get-color('warm-grey');
                        color: #fff;
                    }
                }
            }
        }
    }
}
