body.template-landing.theme-ullensvang main::before {
	content: '';
	width: 100vw;
	height: 100vh;
	position: absolute;
	z-index: -1;
	top: 100vh;
	left: 0;
	background: linear-gradient(to right, #a3ced3 0%,#b9dee3 100%);
}

.landing {
	@include breakpoint(small only) {
		background-color: #fff;
	}

	@include formby() {
		padding-top: 50px;
	}

	

	&__item {
		position: relative;

		&.no-bg {
			padding-bottom: 0;
		}

		&-wrap {
			margin-bottom: 125px;

			@include breakpoint(small only) {
				margin-bottom: 50px;
			}
		}

		&-content {
			position: relative;
			z-index: 10;
			background-color: #fff;
			@include flex-align-center();

			&-inner {
				padding: 20px 20px 100px;

				@include breakpoint(large) {
					padding: 10px 15% 100px;
				}
			}

			h2 {
				@include copy-headings();

				@include ullensvang() {
					color: get-color('dark-blue');
					margin-bottom: 4px;
				}

				@include staverton() {
					margin-bottom: 30px;

					@include breakpoint(small only) {
						margin-bottom: 15px;
					}
				}

				@include formby() {
					text-transform: uppercase;
					margin-bottom: 20px;
				}
			}

			h3 {
				@include sub-headings();

				@include ullensvang() {
					margin-bottom: 5px;
				}

				@include staverton() {
					margin-bottom: 35px;
				}

				@include formby() {
					text-transform: uppercase;
					margin-bottom: 50px;
				}
			}

			h2,
			h3 {
				a {
					color: inherit;
				}
			}

			p {
				@include body-copy();

				margin-left: auto;
				margin-right: auto;

				&:last-child {
					margin-bottom: 68px;
				}
			}

			.carousel-nav {
				position: absolute;
				bottom: 45px;
				left: 0;
				width: 100%;
			}

			.button {
				&:first-of-type {
					margin-right: 15px;
				}

				&:first-of-type:last-of-type {
					margin-right: 0;
				}
			}
		}

		&-slider {
			position: relative;
			z-index: 10;

			.img-slider {
				bottom: 24px;
			}

			&.reveal-image::before {
				z-index: 10;
			}
		}

		&-background {
			@include breakpoint(medium) {
				position: absolute;
				z-index: 5;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				overflow: hidden;

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
					opacity: 0.5;
				}

				@include staverton() {

					img {
						opacity: 0.46;
					}
				}
			}
		}

		&--large-slider-right {

			.landing__item-background {
				@include staverton() {
					display: none;
				}
			}
		}

		&--large-slider-left,
		&--large-slider-right {
			@include breakpoint(medium) {
				padding: 250px 0;
			}

			&:first-child {
				@include breakpoint(medium) {
					padding-top: 0;
				}
			}

			&:not(:first-child) {
				.landing__item-background {
					@include breakpoint(small only) {
						display: none;
					}
				}
			}

			.slick-slider,
			.slick-list,
			.slick-track,
			.slick-slide,
			.slick-slide > div,
			.landing__item-slider-slide {
				height: 100%;
			}

			.landing__item-slider-slide {
				display: flex !important;

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}


		&--centered {
			@include breakpoint(medium) {
				margin-top: -125px;
				margin-bottom: -125px;
			}

			.landing__item-content {
				transform: translateY(-10px);
			}

			.landing__item-content-inner {
				@include breakpoint(medium) {
					padding: 33px 20% 150px;
				}
			}
		}

		&.no-slider {

			.landing__item-content-inner {
				padding-bottom: 30px;
			}
		}
	}
}

.mia-accreditation {
	width: 100%;
	text-align: center;
	padding: 62px 0px 62px 0px;

	p {
		font-size: 30px;
		font-family: "L Baskerville W01",serif;
		color: #4D5A5F;
	}

	img {
		height: 62px;
		margin: 0 25px 0 25px;
	}
}
