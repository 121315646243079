.clear { clear: both; }
.cf:before,
.cf:after {
    content: " ";
    display: table;
}
.cf:after {
    clear: both;
}
.cf {
    *zoom: 1;
}