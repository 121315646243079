.accordion {

    @include staverton() {
        @include breakpoint(large) {
            max-width: 70%;
            margin-inline: auto;
        }
    }

    &__wrap {

        @include staverton() {
            margin-bottom: 60px;

            @include breakpoint(small only) {
                margin-bottom: 30px;
            }
        }
    }

    .accordionButton {
        padding: 32px 20px 19px;
        margin-top: 25px;
        background-color: #fff;
        cursor: pointer;

        @include formby() {
            background-color: get-color('light-grey');
        }

        h3 {
            @include sub-headings;
            position: relative;

            @include staverton() {
                font-family: $staverton-primary-font;
                text-transform: none;
                font-size: 30px;
                letter-spacing: none;
            }

            @include breakpoint(small only) {
                @include staverton() {
                    font-size: 28px;
                }
            }

            @include formby() {
                font-size: 25px;
                color: get-color('mid-warm-grey');
                text-transform: uppercase;
                font-weight: 400;
            }

            &::after {
                content: '';
                font-size: 20px;
                font-family: $ullensvang-primary-font;
                font-weight: 700;
                color: get-color('dark-blue');
                background: url('img/ullensvang/accordion-open-dark-blue.svg') no-repeat left top;
                position: absolute;
                top: 0px;
                right: 50px;
                width: 20px;
                height: 20px;

                @include staverton() {
                    font-family: $staverton-primary-font;
                    font-weight: 400;
                    text-transform: none;
                    letter-spacing: normal;
                    font-size: 16px;
                    color: get-color('pickled-blue');
                    background: url('img/staverton/accordion-open-pickled-blue.svg')
                }

                @include formby() {
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: normal;
                    font-size: 12px;
                    color: get-color('warm-grey');
                    background: url('img/formby/accordion-open-warm-grey.svg') no-repeat left top;
                }

                @include breakpoint(small only) {
                    content: '';
                    background: url('img/ullensvang/ullensvang-mobile-open-dark-blue.svg') no-repeat center right;
                    width: 16px;
                    height: 16px;
                    top: 5px;
                    right: 0px;

                    @include staverton() {
                        background: url('img/staverton/accordion-open-pickled-blue.svg') no-repeat left top;
                        top: 0px;
                        right: 0px;
                        width: 20px;
                        height: 20px;
                    }

                    @include formby() {
                        background: url('img/formby/accordion-open-warm-grey.svg') no-repeat left top;
                        top: 0px;
                        right: 0px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        &.on {

            h3 {
                &::after {
                    content: 'close';
                    right: 0;
                    background: url('img/ullensvang/ullensvang-close-lines-dark-blue.svg') no-repeat;
                    background-position: 0% 33%;
                    background-size: 30px 20px;
                    width: 90px;
                    height: 50px;
                    padding-left: 30px;

                    @include breakpoint(small only) {
                        content: '';
                        background: url('img/ullensvang/ullensvang-mobile-close-dark-blue.svg') no-repeat center right;
                        width: 16px;
                        height: 16px;
                        top: 5px;
                        right: 0px;

                        @include staverton() {
                            background: url('img/staverton/staverton-mobile-close-pickled-blue.svg') no-repeat center right;
                        }

                        @include formby() {
                            background: url('img/formby/formby-mobile-close-warm-grey.svg') no-repeat center right;
                        }
                    }

                    @include staverton() {
                        background: url('img/staverton/staverton-close-lines-pickled-blue.svg') no-repeat;
                    }

                    @include formby() {
                        background: url('img/formby/formby-close-lines-warm-grey.svg') no-repeat;
                    }
                }
            }
        }
    }

    .accordionContent {
        padding: 0 5% 30px;
        background-color: #fff;

        @include formby() {
            background-color: get-color('light-grey');
        }

        @include staverton() {
          a {
            color: #C1996D;
            font-weight: bold;

            &:hover {
              color: #4D5A5F;
              font-weight: bold;
            }
          }

        }

        p {
            @include body-copy;
        }
    }
}

.intro__content a, .intro__content a:visited, .intro__content a:hover, .intro__content a:active {
    color: #C1996D;
    font-weight: 700;
}

.intro__content a:hover {
  color: #4D5A5F;
  font-weight: 700;
}
