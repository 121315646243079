@charset 'utf-8';

$foundation-palette: (
	primary: #000,
	secondary: #000,
	success: #000,
	warning: #000,
	alert: #000,


	// ullensvang
	'dark-blue': #2a4a71,
	'aqua': #4091a4,
	'mid-aqua': #A0C8D1,
	'pale-aqua': #e3ecec,
	'dark-grey': #3e3e3f,
	'pale-grey': #F5F5F5,

	//Staverton
	'castle-grey': #A6ACAE,
	'eggs-hell': #F5F1E9,
	'grey-black': #242424,
	'golden-brown': #81642C,
	'pickled-blue': #4D5A5F,
	'black-blue': #011d2f,
	'orange': #C1996D,

	//Formby
	'warm-grey': #4d4946,
	'cold-grey': #8c8d8d,
	'mid-warm-grey': #a6a5a0,
	'light-grey': #f3f4f5

);

$body-background: #fff;


$off-canvas-breakpoint: 1170px;

$grid-container: 1300px;
$grid-container-padding: (
	small: 40px
);

$grid-margin-gutters: 25px;



$ullensvang-primary-font: 'Yellow Peas', sans-serif;
$ullensvang-secondary-font: 'neue-haas-grotesk-display', sans-serif;

$staverton-primary-font: 'L Baskerville W01', 'serif';
$staverton-secondary-font: 'Raleway', 'sans-serif';

$formby-font: multi-display, sans-serif;
