.intro {
	text-align: center;
	margin-bottom: 30px;

	@include staverton() {
		@include breakpoint(small only) {
			margin-top: 50px;
		}
	}

	@include formby() {
		@include breakpoint(small only) {
			margin-top: 50px;
		}
	}

	

	@include ullensvang() {
		background-color: #fff;
		padding-top: 50px;

		@include breakpoint(small only) {
			margin-top: 10px;
			padding-top: 50px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	@include staverton() {

		@include breakpoint(small only) {
			margin-bottom: 30px;
		}
	}

	@at-root body.template-home.theme-staverton #{&} {
		margin-top: 60px;

		h1 {
			margin-bottom: 60px;
		}

		.button {
			margin-top: 0!important;
		}

		@include breakpoint(small only) {
			margin-top: 30px;

			h1 {
				margin-bottom: 48px;
			}

			.button {
				margin-top: 20px!important;
			}
		}
	}
	
	@at-root body.template-home.theme-formby #{&} {
		margin-top: 60px;
	}

	@at-root body.template-landing.theme-ullensvang #{&} {
		background-color: transparent;
	}

	@at-root body.template-accommodation-landing.theme-ullensvang #{&} {
		background-color: transparent;
	}

	@at-root body.template-transparent-gradient.theme-ullensvang #{&} {
		background-color: transparent;
	}

	@at-root body.template-form.theme-ullensvang #{&} {
		margin: 0;
		padding-left: 20px;
		padding-right: 20px;
	}

	@at-root body.template-concierge-landing.theme-ullensvang #{&} {
		margin: 0;
		padding-left: 20px;
		padding-right: 20px;
	}

	@at-root body.template-gallery.theme-ullensvang #{&} {
		margin: 0;
		padding-left: 20px;
		padding-right: 20px;
	}

	@at-root body.template-offers-landing.theme-ullensvang #{&} {
		margin: 0;
		padding-left: 20px;
		padding-right: 20px;
	}

	&:first-child {
		@include ullensvang() {
			padding-top: 75px;

			@include breakpoint(small only) {
				padding-top: 50px;
			}

			@include breakpoint(large) {
				margin-top: 53px;
			}
		}
	}

	&__header {
	}

	&__content {
		@include breakpoint(medium) {
			padding-bottom: 75px;
		}
	}

	h1 {
		@include page-title();

		@include ullensvang() {
			text-transform: lowercase;
			margin-bottom: 20px;

			@include breakpoint(small only) {
				margin-bottom: 35px;
			}
		}

		@include staverton() {
			@include breakpoint(small only) {
				line-height: 42px !important;
			}
		}

		@include formby() {
			color: get-color('warm-grey');
			margin-bottom: 35px;

			@include breakpoint(small only) {
				margin-bottom: 25px;
			}
		}
	}

	p {
		@include intro-copy();

		@include formby() {
			color: get-color('warm-grey');
		}
		
		a {
			color: inherit;
			text-decoration: underline;
		}
	}

	&__cta {
		@include breakpoint(small only) {
			padding-top: 25px;
		}

		.button {
			@include ullensvang() {
				margin-bottom: 100px;

				@include breakpoint(small only) {
					margin-bottom: 50px;
				}
			}
		}
	}

	@include staverton() {
		margin-top: 60px;

		h1 {
			margin-bottom: 34px;
		}

		.button {
			margin-bottom: 100px;
		}

		@include breakpoint(small only) {
			h1 {
				margin-bottom: 40px;
			}
			.button {
				margin-bottom: 50px;
			}
		}
	}
}
