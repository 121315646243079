.filter {
	text-align: center;
	margin-bottom: 50px;

	@at-root body.template-offers-landing.theme-ullensvang #{&} {
		margin: 0;
		padding-left: 20px;
		padding-right: 20px;
		background-color: #fff;

		@include breakpoint(small only) {
			padding: 20px 20px 30px;
		}
	}

	span {
		@include sub-headings;
		margin-top: 5px;
		margin-right: 10px;

		@include formby() {
			text-transform: uppercase;
			font-weight: 400;
			margin-top: 15px;
		}
	}

	span,
	.filter__buttons,
	.offers__filters-buttons {
		display: inline-block;
		vertical-align: top;

		@include staverton() {
			vertical-align: middle;
			padding-top: 6px;
		}
	}

	a,
	button {
		text-transform: capitalize;
		margin: 0px 10px;
	}

	&__buttons {

		.button {
			@include ullensvang() {
				font-family: $ullensvang-primary-font;
				font-weight: normal;
				font-size: 25px;
				line-height: 40px;
				padding: 3px 25px;
				color: get-color('dark-grey');
				background-color: transparent;
				text-transform: initial;
				margin: 0 12px 12px;

				&:hover,
				&:active {
					color: get-color('dark-grey');
					background-color: get-color('pale-aqua');
				}

				&.on {
					background-color: get-color('pale-aqua');
				}
			}

			@include staverton() {
				background-color: transparent;
				font-size: 20px;

				&:hover,
				&:active {
					background-color: get-color('pickled-blue');
				}

				&.on {
					background-color: get-color('pickled-blue');
					color: #fff;
				}
			}

			@include formby() {

				&.on {
					background-color: get-color('warm-grey');
					color: #fff;
				}
			}
		}
	}

	.selectric-wrapper {
		top: 6px;
	}
}
