.button {
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	@include ullensvang() {
		margin: 0;
		padding: 15px 35px;
		font-family: $ullensvang-primary-font;
		font-weight: bold;
		font-size: 18px;
		letter-spacing: 0.025em;
		text-transform: lowercase;
		color: #fff;
		background-color: get-color('dark-blue');

		&:hover,
		&:active {
			color: get-color('dark-blue');
			background-color: get-color('pale-aqua');
		}
	}

	@include staverton() {
		font-family: $staverton-primary-font;
		font-size: 21px;
		line-height: 19px;
		letter-spacing: 0.025em;
		background-color: get-color('eggs-hell');
		color: get-color('pickled-blue');
		padding: 14px 20px;

		&:hover {
			background-color: get-color('black-blue');
			color: #fff;
		}
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 12px;
		line-height: 19px;
		letter-spacing: 0.1em;
		font-weight: 500;
		background-color: transparent;
		border: 1px solid get-color('mid-warm-grey');
		color: get-color('warm-grey');
		text-transform: uppercase;
		padding: 14px 20px;
		min-width: 125px;

		&:hover {
			background-color: get-color('warm-grey');
			color: #fff;
		}
	}
	
	&--primary {
		@include staverton() {
			color: #fff;
			background-color: get-color('orange');
	
			&:hover,
			&:active {
				background-color: get-color('black-blue');
				color: #fff;
			}
		}

		@include formby() {
			background-color: get-color('light-grey');
			color: get-color('warm-grey');
	
			&:hover {
				background-color: get-color('warm-grey');
				color: #fff;
			}
		}
	}
}

.multiple-buttons {
	text-align: center;
	width: 100%;

	.button {
		margin: 0 12px 15px;

		@include breakpoint(large) {
			&:first-of-type {
				margin-left: 0 !important;
			}

			&:last-child {
				margin-right: 0 !important;
			}
		}
	}
}
