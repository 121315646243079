.standard-content {
	position: relative;

	

	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 10px;
		right: 10px;
		bottom: 0;
		background: #fff;

		@include breakpoint(medium) {
			left: 20px;
			right: 20px;
		}

		@include breakpoint(large) {
			left: 60px;
			right: 60px;
		}
	}

	> .grid-x {
		margin-bottom: 20px;

		@include breakpoint(medium) {
			margin-bottom: 75px;
		}

		&:last-child {
			@include breakpoint(medium) {
				margin-bottom: -70px;

				@include formby() {
					margin-bottom: -40px;
				}
			}
		}


	}

	&__image {
		@include breakpoint(small only) {
			padding: 0 20px;
			margin-bottom: 40px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&-wrapper {
			position: relative;
		}
	}

	&__text {
		padding-bottom: 40px;

		@include breakpoint(small only) {
			padding-left: 20px;
			padding-right: 20px;
		}

		@include breakpoint(large) {
			padding: 5px 4.15% 40px;
		}

		@include formby() {
			text-align: center;
		}

		&-inner {
			text-align: center;

			h2 {
				padding-top: 1rem;
			}
		}

		h2 {
			@include copy-headings();
			margin-bottom: 3px;

			@include staverton() {
				margin-bottom: 28px;
			}

			@include formby() {
				text-transform: uppercase;
				margin-bottom: 20px;
			}
		}

		h3 {
			@include sub-headings();
			margin-bottom: 8px;

			@include staverton() {
				margin-bottom: 40px;
			}

			@include formby() {
				text-transform: uppercase;
				margin-bottom: 50px;
			}
		}

		p {
			@include body-copy();
		}

		.multiple-buttons {
			padding-top: 52px;
		}
	}

	&__text-image-row {
		.standard-content__text {
			min-height: 100%;
			@include flex-align-center();

			h2,
			h3 {
				max-width: 577px;
			}

			p {
				max-width: 307px;
			}

			h2,
			h3,
			p {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&--image-right {
			
			.standard-content__text-inner {
				padding-top: 1rem;
    			padding-left: 0rem;

				@include breakpoint (large) {
					padding-top: 1rem;
					padding-left: 3rem;
				}
			}
		}

		&--image-left {

			.standard-content__text-inner {
				padding-top: 1rem;
    			padding-right: 0rem;

				@include breakpoint (large) {
					padding-top: 1rem;
					padding-right: 3rem;
				}
			}
		}

	}

	@include staverton {
		ul, li {
			font-size: 15px;
		}

		 /* a { 
					color: #C1996D;
					font-weight: bold;

					&:hover {
						color: #4D5A5F;
						font-weight: bold;
					}
				 }
				 */ 
	}
}



.standard-content.whats-on {
	@include formby() { 
		max-width: 1270px;
		margin:0 auto;
	}

	.standard-content__text-image-row {
		justify-content: center;
	}

	.standard-content__image {
		display: flex;

		.img-credit {
			bottom: 66px;

			@include breakpoint(small only) {
				right: 36px;
				bottom: 16px;
			}
		}

		@include breakpoint(small only) {
			padding: 0 20px;
			margin-bottom: 40px;
		}

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

		@include formby() {
			// width: 100%;
			// // max-width: 400px;
			text-align: center;
			
			img {
				object-fit: contain;
				width: 100%;
				min-width: 290px;
				// max-width: 500px;
				height: auto;
				display: block;
				margin: 0 auto;
			}
		}
	}
}
