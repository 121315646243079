.off-canvas {
	z-index: 50000;
	transform: translateX(-100%);
    width: 100%;
	display: flex;
	flex-direction: column;

    @include ullensvang() {
        background-color: get-color('dark-blue');
    }

	@include staverton() {
		background-color: get-color('pickled-blue');
	}

	@include formby() {
		background-color: get-color('warm-grey');
	}
	
	&.is-open {
		transform: translateX(0);
	}
	
	&__header {
		position: relative;
		width: 100%;
	}
	
	&__main {
		width: 100%;
		position: relative;
		z-index: 5;
		margin-top: 100px;
	}
	
	&__wrapper {
		width: 100%;
		max-width: 1260px;
		margin: auto;
	}

    &__inner {
        padding: 20px;

		p {
			text-align: center;
		}
    }

    &__lang {
        position: absolute;
		top: 20px;
		left: 144px;
		
		@include breakpoint(small only) {
			left: initial;
			right: 20px;
		}
		
		button {
			position: relative;
			cursor: pointer;
			width: 100%;
			height: 28px;
			text-align: left;
			
			@include ullensvang() {
				font-size: 18px;
				letter-spacing: 0.1em;
				font-weight: bold;
				text-transform: lowercase;
				color: #fff;
			}

			@include staverton() {
				font-size: 14x;
				letter-spacing: 0.1em;
				font-weight: bold;
				text-transform: lowercase;
				color: #fff;
			}
			
			&::after {
				content: '';
				display: inline-block;
				width: 13px;
				height: 8px;
				background: url('img/arrow-down-white.svg') no-repeat center;
			}
		}
		
		ul {
			position: absolute;
			z-index: 20000;
			top: 27px;
			right: 0;
			width: 100%;
			padding: 0;
			border: none;
			margin: 0;
			list-style-type: none;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.3s ease-in-out;
			
			@include ullensvang() {
				background: get-color('dark-blue');
			}

			@include staverton() {
				background: get-color('pickled-blue');
			}

			@include formby() {
				background: get-color('warm-grey');
			}
		}
		
		&:hover ul {
			opacity: 1;
			pointer-events: all;
		}
		
		a {
			color: #fff;
			
			@include ullensvang() {
				font-size: 18px;
				letter-spacing: 0.1em;
				font-weight: bold;
				text-transform: lowercase;
				background-color: get-color('dark-blue');

                &:hover {
                    color: #fff;
                }
			}
		}
    }

    &__logo {
        width: 80%;
		max-width: 136px;
		margin: 0 auto 17px;
		padding-top: 25px;
		transition: all 0.3s ease-in-out;
		
		path {
			fill: #fff;
		}
		
		@include staverton() {
			max-width: 200px;

			rect {
				stroke: #fff;
			}
		}
		
		@include formby() {
			max-width: 200px;
			
			rect {
				fill: #fff;
			}
		}
    }
	
	&__close {
		position: absolute;
		top: 20px;
		left: 53px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		width: 53px;
		@include body-copy();
		font-weight: bold !important;
		font-size: 18px;
		line-height: 18px !important;
		color: #fff !important;
		
		@include ullensvang() {
			text-transform: lowercase;
		}
		
		@include breakpoint(small only) {
			left: 20px;
		}
		
		rect {
			transform-origin: left center;
			transition: all 0.3s ease-in-out;

			&#burger-line-top {
				transform: rotate(45deg) translate(0, -4px);
			}
			
			
			&#burger-line-middle {
				transform: rotate(45deg) translate(-7px,-9px);
			}
			
			&#burger-line-bottom {
				opacity: 0;
			}
			
			@include ullensvang() {
				fill: #fff;
			}

			@include staverton() {
				fill: #fff;
			}

			@include formby() {
				fill: #fff;
			}
		}

		&-wrap {
			text-align: right;
		}
	}
	
	&__nav {
padding-top: 0 !important;
	}
	
	&__nav-primary {
		margin-bottom: 50px;
		
		> ul {
			margin: 0;
			list-style-type: none;
			
			> li {
				margin-bottom: 27px;
				
				@include breakpoint(small only) {
					margin-bottom: 20px;
				}

                a {
                    @include slider-sub-heading;
					transition: color 0.3s ease-in-out;
					
                    @include ullensvang() {
                        font-weight: 700;
                        color: #fff;
                        font-size: 23px;
                        line-height: 26px;
						text-transform: lowercase;
						
						&:hover,
						&:active {
							color: get-color('mid-aqua');
						}
                    }

					@include staverton() {
						font-family: $staverton-primary-font;
						font-size: 23px;
						line-height: 26px;
						letter-spacing: 0;
						color: #fff;
						text-transform: initial;
					}

					@include formby() {
						font-size: 16px;
						line-height: 26px;
						letter-spacing: 0;
						color: #fff;
						text-transform: uppercase;
					}
                }
				
				&.open > a {
					@include ullensvang() {
						color: get-color('mid-aqua');
					}
				}
				
				> ul {
					margin: 8px 0 0 34px;
					list-style-type: none;
					display: none;
					
					@include breakpoint(small only) {
						margin-left: 25px;
					}
					
					> li {
						margin-bottom: 8px;
						
						@include breakpoint(small only) {
							line-height: 1;
							margin-bottom: 20px;
						}

                        a {
                            font-size: 17px !important;
                            line-height: 26px;
                        }
					}
				}
			}
		}
	}
	
	
	&__nav-header {
		text-align: left;
        padding: 20px 0 60px;

		@include formby() {
			padding: 20px 0;
		}
		
		ul {
			margin: 0;
			list-style-type: none;
		}
		
		li {
			display: block;
			margin-bottom: 10px;
			
			&:first-child {
				margin-left: 0;
			}
			
			&:last-child {
				margin-right: 0;
			}

            a {

                @include ullensvang() {
                    font-size: 17px;
                    line-height: 26px;
                    font-weight: 700;
                    color: #fff;
                    font-family: $ullensvang-primary-font;
                }

				@include staverton() {
					font-size: 17px;
					line-height: 26px;
					color: #fff;
					font-family: $staverton-secondary-font;
				}

				@include formby() {
					font-size: 12px;
					line-height: 26px;
					color: #fff;
					text-transform: uppercase;
				}
            }
		}
	}
	
	&__footer {
		width: 100%;
        border-top: 1px solid rgba(#fff, 0.4);
	}
	
	&__nav-footer-primary {
		width: 100%;
		text-align: center;
		
		ul {
			margin: auto;
		}
		
		li {
			display: inline;
			margin: 0 9px;
			white-space: nowrap;
		}
	}
	
	&__offer {

		&-button {
			background-color: get-color('pickled-blue') !important;
			color: white !important;
			display: flex;
    		justify-content: center;

			&:hover {
				background-color: get-color('orange') !important;
			}
		}

		img {
			display: block;
			margin-bottom: 0;
		}
		
		&-inner {
			padding: 9px 30px 5px;
			background-color: #F5F1E9;
			
			h2 {
				color: #4D5A5F;
    			font-size: 25px;
   				font-family: $staverton-primary-font;
    			margin-bottom: 12px;
				text-align: center;
			}

			h3,
			.off-canvas__offer-price {
				@include body-copy;
				margin-bottom: 10px;
				font-size: 15px !important;
    line-height: 25px !important;
    color: #4D5A5F !important;
	font-style: normal !important;
	text-align: center !important;
				
				@include ullensvang() {
					text-transform: lowercase;
				}
				
				a {
					color: inherit;
				}
			}
			
			.off-canvas__offer-price {
				margin-bottom: 40px;
			}
			
			.button {
				min-width: 180px;
				margin-bottom: 20px !important;
			}
		}
	}

    &__newsletter {
		margin-top: auto;
    }
}

.off-canvas__price {
	text-align: center !important;
}