.gallery {
	margin-bottom: 130px;
	background-color: #fff;

	@include breakpoint(small only) {
		margin-bottom: 50px;
	}

	&__grid {}

	&__item {
		width: 25%;
		float: left;
		padding: 5px;

		@include breakpoint(medium only) {
			width: 50%;
		}

		@include breakpoint(small only) {
			width: 100%;
			padding-bottom: 10px;
		}

        &.double-width {
            width: 50%;

            @include breakpoint(medium down) {
                width: 100%;
            }
        }

		&-sizer {
			width: 25%;
			padding: 5px;

			@include breakpoint(medium only) {
				width: 50%;
			}

			@include breakpoint(small only) {
				width: 100%;
			}
		}

		a {
			position: relative;
			display: block;

			&::before {
				content: '';
				position: absolute;
				z-index: 5;
				top: 10px;
				right: 10px;
				width: 16px;
				height: 16px;
				background: url('img/magnifying-glass-white.svg') no-repeat center center;
				background-size: 16px 16px;
			}
		}

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
	}

	&__modal {

		&-close {
			position: relative;
			width: 100%;
			height: 114px;
			text-align: right;
			padding-left: 18px;
			transition: all 0.3s ease-in-out;
			cursor: pointer;

			span {
				@include ullensvang() {
					display: block;
					font-family: $ullensvang-primary-font;
					font-size: 23px;
					font-weight: bold;
					text-transform: lowercase;
					color: get-color('dark-blue');

				}

				@include staverton() {
					font-family: $staverton-primary-font;
					font-size: 23px;
					color: get-color('pickled-blue');
					display: block;
				}

				@include formby() {
					font-size: 15px;
					color: get-color('warm-grey');
					display: block;
					padding-right: 10px;
				}
			}

			rect {
				transform-origin: left center;
				transition: all 0.3s ease-in-out;

                &#burger-line-top {
                    transform: rotate(45deg) translate(0, -4px);
                }


                &#burger-line-middle {
                    transform: rotate(45deg) translate(-7px,-9px);
                }

                &#burger-line-bottom {
                    opacity: 0;
                }

				@include ullensvang() {
					fill: get-color('dark-blue');
				}

				@include formby() {
					fill: get-color('warm-grey');
				}
			}
		}

		&-image {
			position: relative;
			padding: 0px 30px 10px;

			@include breakpoint(small only) {
				padding: 0px 0 10px;
			}

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
		}

		&-prev,
		&-next {
			position: absolute;
			top: 50%;
			margin-top: -8px;
			width: 21px;
			height: 21px;
			text-indent: -12345px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 21px 21px;
			cursor: pointer;
		}

		&-prev {
			left: 0;
			background-image: url('img/arrow-left-dark-blue.svg');

			@include staverton(){
				background-image: url('img/staverton/arrow-left-pickled-blue.svg');
			}

			@include formby(){
				background-image: url('img/formby/arrow-left-warm-grey.svg');
			}

			&:hover,
			&:active {
				background-image: url('img/arrow-left-aqua.svg');

				@include staverton(){
					background-image: url('img/staverton/arrow-left-light-grey.svg');
				}

				@include formby(){
					background-image: url('img/formby/arrow-left-light-grey.svg');
				}
			}
		}

		&-next {
			right: 0;
			background-image: url('img/arrow-right-dark-blue.svg');

			@include staverton(){
				background-image: url('img/staverton/arrow-right-pickled-blue.svg');
			}

			@include formby(){
				background-image: url('img/formby/arrow-right-warm-grey.svg');
			}

			&:hover,
			&:active {
				background-image: url('img/arrow-right-aqua.svg');

				@include staverton(){
					background-image: url('img/staverton/arrow-right-light-grey.svg');
				}

				@include formby(){
					background-image: url('img/formby/arrow-right-light-grey.svg');
				}
			}
		}

		&-content {
			padding: 0 30px 15px;
			font-size: 12px;

			@include formby() {
				padding: 0 15px 15px;
			}
			
			&.grid-margin-x {
				@include breakpoint(small only) {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}

		&-caption {

            @include sub-headings;

			@include breakpoint(small only) {
				text-align: center;
				margin-bottom: 15px;
			}

			@include formby() {
				font-weight: 400;
				margin-top: 15px;
			}
		}

		&-cta {
			text-align: center;
            @include sub-headings;

			@include breakpoint(medium) {
				text-align: right;
			}

			@include formby() {
				font-weight: 400;
			}


			.button, a {
				margin-left: 15px !important;

				@include breakpoint(small only) {
					margin-top: 20px;
				}

				@include staverton(){
					text-transform: none;
				}

				@include formby() {
					margin-bottom: 0;
				}
			}

			.button {
				@include staverton() {
					background-color: get-color('eggs-hell');
					color: get-color('pickled-blue');

					&:hover {
						background-color: get-color('pickled-blue');
						color: #fff;
					}
				}
			}
		}

		&-iframe {
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			padding-top: 25px;
			height: 0;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

	}
}
