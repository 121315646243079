.concierge-landing {
    margin-bottom: 120px;

    @include formby() {
        padding-top: 75px;
    }

    @include breakpoint(small only) {
        margin-bottom: 60px;
    }

    h4 {
        @include copy-headings;
        max-width: 577px;
        width: 100%;
        margin: 0 auto 7px;

        @include breakpoint(small only) {
            margin-bottom: 30px;
        }

        a {
            color: inherit;
        }

        @include staverton() {
          margin-bottom: 30px;

          @include breakpoint(small only) {
            margin-bottom: 15px;
          }
        }

        @include formby() {
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }

    h5 {
        @include sub-headings;
        max-width: 577px;
        width: 100%;
        margin: 0 auto 8px;

        @include breakpoint(small only) {
            margin-bottom: 30px;
        }

        a {
            color: inherit;
        }

        @include staverton() {
          margin-bottom: 35px;
        }

        @include formby() {
            text-transform: uppercase;
            margin-bottom: 50px;
        }
    }

    p {
        @include body-copy;
        max-width: 307px;
        width: 100%;
        margin: 0 auto;
    }

    &__featured-item-cta,
    &__grid-item-cta {
        padding-top: 19px;
    }

    &__featured {
        margin-top: -25px;
        margin-bottom: 50px;


        @include breakpoint(small only) {
            margin-bottom: 15px;
        }

        &-item {
            display: flex;
            align-items: center;

            &-wrap {
                position: relative;
                margin: 0 auto;
                width: 100%;
                height: calc(100% - 160px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: #fff;

                @include breakpoint(small only) {
                    height: 100%;
                    padding: 40px 0;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: -80px;
                    left: 0;
                    width: 100%;
                    height: 80px;
                    background-color: #fff;
                }
            }

            &-info {
                margin-bottom: 50px;

                @include breakpoint(small only) {
                    margin-bottom: 30px;
                }
            }

            &-image {

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &__grid {
        @include breakpoint(medium) {
            margin-bottom: -135px;
        }

        @include breakpoint(small only) {
            margin: 0;
            padding: 0;
        }

        &-inner {
            @include breakpoint(small only) {
                margin: 0 auto !important;
            }
        }

        &-item {
            margin-bottom: 165px;

            @include breakpoint(small only) {
                padding-bottom: 20px;
                margin: 0 0 15px !important;
                width: 100% !important;
            }

            &:nth-of-type(even) {
                transform: translateY(90px);

                @include breakpoint(small only) {
                    transform: translateY(0px);
                }
            }

            &-wrap {
                background-color: #fff;
                padding-bottom: 50px;

                @include breakpoint(medium) {
                    padding-bottom: 100px;
                }
            }

            &-image {
                margin-bottom: 32px;

                @include breakpoint(small only) {
                    margin-bottom: 0px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-info {
                margin-bottom: 50px;

                @include breakpoint(small only) {
                    margin-bottom: 30px;
                    padding: 40px 0;
                }
            }
        }
    }
}
