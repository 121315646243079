.detail {
	padding-bottom: 25px;

	@include breakpoint(small only) {
		padding: 0 10px 25px;
	}

	@include formby() {
		padding-top: 75px;
	}

	&__content {
		display: flex;
		flex-direction: column;

		@include breakpoint(large) {
			padding-left: 66px;
		}

		p {
			@include body-copy;

			&:first-child {
				@include ullensvang() {
					@include intro-copy;
					margin-bottom: 40px;
					color: get-color('dark-grey') !important;
				}

				@include staverton() {
					@include body-copy;

					@include breakpoint(small only) {
						font-size: 15px;
						font-weight: 400;
					}
				}

				@include formby() {
					font-family: $staverton-secondary-font;
					font-size: 20px;
					line-height: 27px;
					margin-bottom: 27px;

					@include breakpoint(small only) {
						font-size: 17px;
					}
				}
			}
		}
	}

	&__cta {
		display: flex;
		flex-direction: column;
	

		p {
			font-family: multi-display, sans-serif !important;
    font-size: 15px !important;
    line-height: 28px !important; 
    font-weight: 500 !important;
		}

		&-container {
			display: flex;
			gap: 40px;
		}
	}

	&__rate {
		margin-top: auto;
		margin-bottom: 50px;


		@include breakpoint(small only) {
			padding-top: 0;
		}

		@include staverton() {
			@include breakpoint(small only) {
				padding-top: 10px;
			}

			.button {
				background-color:  get-color('orange');
				color: #fff;

				&:hover {
					background-color:  get-color('black-blue');
				}
			}
		}

		h3 {
			@include price();
			line-height: 36px;
			margin: 0;

			@include staverton() {
				margin-bottom: 10px;

				@include breakpoint(small only) {
					margin-bottom: 0px;
				}
			}

			@include formby() {
				font-style: italic;
			}
		}

		p {
			@include body-copy();
			margin-bottom: 22px;

			@include ullensvang() {
				font-size: 22px;
			}

			@include staverton() {
				font-size: 20px;
				margin-bottom: 65px;

				@include breakpoint(small only) {
					margin-bottom: 40px;
				}
			}

			@include formby() {
				font-size: 20px;
				font-weight: 300;
				margin-bottom: 50px;
			}
		}
	}

	&__features {
		@include breakpoint(medium) {
			padding-left: 60px;
		}

		h2 {
			@include similar-things-to-do();
			margin-bottom: 30px;

			@include formby() {
				color: get-color('mid-warm-grey');
				text-transform: uppercase;
			}
		}

		ul {
			list-style-type: none;
			margin: 0 0 30px;

			li {
				position: relative;
				padding-left: 15px;

				&::before {
					content: '-';
					position: absolute;
					top: 0;
					left: 0;
				}

				@include bullet-list();

				@include formby() {
					font-weight: 500;
				}
			}
		}
	}

	&__quote {
		position: relative;
		border: 0;
		margin: 0;
		padding: 45px 0;

		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 0;
			width: 32px;
			height: 28px;
			background: no-repeat;
		}

		&::before {
			top: 0;
			background-image: url('img/quote-open-dark-blue.svg');

			@include staverton() {
				background-image: url('img/quote-open-pickled-blue.svg');
			}

			@include formby() {
				background-image: url('img/quote-open-mid-warm-grey.svg');
			}
		}

		&::after {
			bottom: 0;
			background-image: url('img/quote-close-dark-blue.svg');

			@include staverton() {
				background-image: url('img/quote-close-pickled-blue.svg');
			}

			@include formby() {
				background-image: url('img/quote-close-mid-warm-grey.svg');
			}
		}

		p {
			@include quote();
			margin: 0;
		}
	}
}


.detail__content h2 {
	font-family: "Yellow Peas", sans-serif;
    font-size: 43px;
    line-height: 40px;
    font-weight: 700;
    text-transform: none;
    color: #2a4a71;
	}

.detail__cta-container {


	@include formby() {
		display: flex;
		gap: 40px;
	}
}