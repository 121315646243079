.selectric {
    border: 0;
    color: get-color('dark-grey');
    background-color: transparent;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 14px;
        right: 0;
        background: url('img/arrow-down-dark-blue.svg') no-repeat center center;
        width: 10px;
        height: 10px;
    }

    span {
        color: get-color('dark-grey') !important;
    }

    .button {
        display: none;
    }

    &-wrapper {
        top: 15px;
    }
}