.slider {
	position: relative;
	text-align: center;

	.slider__inner {
		width: 100vw;
		height: 100vh;

		@at-root body.slider-proportional #{&} {
			height: 0;
			padding-bottom: 140%;

			@include breakpoint(medium) {
				padding-bottom: 70%;
			}
		}

		.slick-list	{
			height: 100%;
		}
	}

	&__slide {
		&::before,
		&::after {
			content: '';
			position: absolute;
			z-index: 10;
			width: 100%;
			left: 0;
		}

		&::before {
			top: 0;
			height: 27%;
			max-height: 200px;
			background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);

			@include breakpoint(small only) {
				background: transparent;
			}
		}

		&::after {
			bottom: 0;
			height: 56%;
			background: linear-gradient(to top, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%);

			@include breakpoint(small only) {
				background: transparent;
			}

			@include formby() {
				// background: linear-gradient(to top, rgba(47,47,47,1) 0%,rgba(0,0,0,0) 100%);
				background: transparent;
			}
		}
	}

	&__image,
	&__video {
		position: relative;
		display: flex;
		height: 100%;

		@include breakpoint(small only) {
			position: relative;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: no-repeat center center;
			background-size: cover;

			// .img-credit {
			// 	bottom: 60px;
			// }
		}

		img {
			object-fit: cover;
			width: 100vw;
			height: 100vh;
		}

		iframe,
		video {
			position: absolute;
			width: 1480px; height: 832.5px; left: 0px; top: -53.75px;
		}
	}

	&__video {
		height: 100vh;
		overflow: hidden;
	}

	&__caption {
		position: absolute;
		z-index: 15;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;

		@include breakpoint(small only) {

		}

		h2,
		p {
			position: absolute;
			width: 100%;
			left: 50%;
			transform: translateX(-50%);
			padding: 0 20px;
			color: #fff;
		}

		h2 {
			bottom: 35vh;
			@include slider-caption();

			@include breakpoint(medium) {
				bottom: 30vh;
			}

			@include ullensvang() {
				text-transform: lowercase;
			}

			@include staverton() {
				margin-bottom: 10px;

				@include breakpoint(small only) {
					line-height: 50px !important;
				}

			}

			@include breakpoint(small only) {
				line-height: 45px !important;
			}
		}

		p {
			top: 65vh;
			max-width: 394px;
			@include slider-sub-heading();

			@include breakpoint(medium) {
				top: 70vh;
			}

			@include staverton() {
				line-height: 27px;
			}
		}
	}

	.carousel-nav {
		position: absolute;
		bottom: 12%;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: 33%;

		@include breakpoint(medium only) {
			max-width: 66%;
		}

		@include breakpoint(small only) {
			max-width: 90%;
			bottom: 18%;
		}

		.slick-arrow {

			&.slick-prev {
				background-image: url('img/carousel-prev-white.svg');

				@include staverton() {
					background-image: url('img/carousel-prev-white.svg');
				}

				@include formby() {
					background-image: url('img/carousel-prev-white.svg');
				}

				&:hover,
				&:active {
					background-image: url('img/carousel-prev-aqua.svg');

					@include staverton() {
						background-image: url('img/carousel-prev-light-grey.svg');
					}

					@include formby() {
						background-image: url('img/carousel-prev-light-grey.svg');
					}
				}
			}

			&.slick-next {
				background-image: url('img/carousel-next-white.svg');

				@include staverton() {
					background-image: url('img/carousel-next-white.svg');
				}

				@include formby() {
					background-image: url('img/carousel-next-white.svg');
				}

				&:hover,
				&:active {
					background-image: url('img/carousel-next-aqua.svg');

					@include staverton() {
						background-image: url('img/carousel-next-light-grey.svg');
					}

					@include formby() {
						background-image: url('img/carousel-next-light-grey.svg');
					}
				}
			}
		}

		.slick-dots {
			width: 100%;

			li {

				button {

					&:after {

						@include ullensvang() {
							border-color: #fff;
						}

						@include staverton() {
							border-color: #fff;
						}

						@include formby() {
							border-color: #fff;
						}
					}
				}
			}
		}
	}
}
