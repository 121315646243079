body.theme-ullensvang .cb {
	&-widget {
		&-activity {
			.cb-gui-datepicker {
				.cb-ui-datepicker-header {
					background-color: get-color('aqua') ;
				}
				
				&.cb-availability td > a,
				&.cb-availability td > a:hover {
					border-color: get-color('aqua');
					background-color: get-color('aqua');
					border-radius: 0;
				}
				
				&.cb-availability td > a.cb-ui-state-active {
					border-color: get-color('dark-blue') !important;
					background-color: get-color('dark-blue') !important;
				}
			}
			
			.cb-btn {
				text-transform: lowercase;
				border-radius: 0;
				color: get-color('dark-blue') !important;
				border: 0 !important;
				background: get-color('pale-aqua') !important;
				transition: all 0.3s ease-in-out;
				
				&:hover {
					color: #fff !important;
					background: get-color('dark-blue') !important;
				}
			}
		}
	}
}
