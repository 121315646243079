.img-credit {
    position: absolute;
    bottom: 16px;
    right: 16px;
    padding: 4px 5px 4px;
    background: rgba(255, 255, 255, 0.8);
    font-size: 11px;
    font-weight: 700;

    span {
        color: #000;
    }
}

.img-credit:has(+ img) {
    position: relative;
}

.detail-gallery .img-credit {
    bottom: 24px;
}

.img-credit--padding {
    position: absolute;
    bottom: 24px;
    right: 16px;
    padding: 1px 5px 7px;
    background: rgba(255,255,255, 0.8);
    font-size: 11px;
}