.vertical-center-table {
	width: 100%;
	height: 100%;
	display: table;
}

.vertical-center-row {
	display: table-row;
}

.vertical-center-cell {
	display: table-cell;
	vertical-align: middle;
}