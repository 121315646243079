@mixin slider-caption {
	@include ullensvang() {
		font-family: $ullensvang-primary-font;
		font-weight: bold;
		font-size: 52px;
		line-height: 43px;

		@include breakpoint(medium) {
			font-size: 80px;
			line-height: 75px;
		}
	}

	@include staverton() {
		font-family: $staverton-primary-font;
		font-size: 50px;
		line-height: 47px;

		@include breakpoint(medium) {
			font-size: 75px;
			line-height: 75px;
		}
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 38px;
		line-height: 41px;
		font-weight: 300;

		@include breakpoint(medium) {
			font-size: 54px;
			line-height: 75px;
			letter-spacing: 0.05em;
		}
	}
}


@mixin slider-sub-heading {
	@include ullensvang() {
		font-family: $ullensvang-primary-font;
		font-size: 23px;
		line-height: 26px;

		@include breakpoint(medium) {
			font-size: 35px;
			line-height: 45px;
		}
	}

	@include staverton() {
		font-family: $staverton-secondary-font;
		font-size: 14px;
		line-height: 26px;
		text-transform: uppercase;

		@include breakpoint(medium) {
			font-size: 18px;
			line-height: 45px;
			letter-spacing: 0.125em;
		}
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 0.125em;

		@include breakpoint(medium) {
			font-size: 18px;
			line-height: 27px;
		}
	}
}


@mixin page-title {
	@include ullensvang() {
		font-family: $ullensvang-primary-font;
		font-size: 40px;
		line-height: 37px;
		font-weight: bold;
		text-transform: lowercase;
		color: get-color('dark-blue');

		@include breakpoint(medium) {
			font-size: 55px;
			line-height: 63px;
		}
	}

	@include staverton() {
		font-family: $staverton-primary-font;
		font-size: 38px;
		line-height: 37px;
		color: get-color('pickled-blue');

		@include breakpoint(medium) {
			font-size: 50px;
			line-height: 63px;
		}
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 27px;
		line-height: 31px;
		letter-spacing: 0.05em;
		text-transform: uppercase;

		@include breakpoint(medium) {
			font-size: 34px;
			line-height: 45px;
		}
	}
}


@mixin intro-copy {
	@include ullensvang() {
		font-family: $ullensvang-secondary-font;
		font-size: 16px;
		line-height: 28px;
		color: get-color('dark-blue');

		@include breakpoint(medium) {
			font-size: 20px;
			line-height: 33px;
		}
	}

	@include staverton() {
		font-family: $staverton-secondary-font;
		font-size: 16px;
		line-height: 30px;
		font-weight: 300;
		color: get-color('pickled-blue');

		@include breakpoint(medium) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 18px;
		line-height: 25px;
		font-weight: 300;

		@include breakpoint(medium) {
			font-size: 19px;
			line-height: 31px;
		}
	}
}


@mixin copy-headings {
	@include ullensvang() {
		font-family: $ullensvang-primary-font;
		font-size: 35px;
		line-height: 40px;
		font-weight: bold;
		text-transform: lowercase;
		color: get-color('dark-blue');

		@include breakpoint(medium) {
			font-size: 45px;
			line-height: 45px;
		}
	}

	@include staverton() {
		font-family: $staverton-primary-font;
		font-size: 35px;
		line-height: 40px;
		color: get-color('pickled-blue');

		@include breakpoint(medium) {
			font-size: 42px;
			line-height: 43px;
		}
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 27px;
		line-height: 31px;
		letter-spacing: 0.05em;

		@include breakpoint(medium) {
			font-size: 29px;
			line-height: 43px;
		}
	}
}


@mixin sub-headings {
	@include ullensvang() {
		font-family: $ullensvang-primary-font;
		font-size: 28px;
		line-height: 32px;
		color: get-color('aqua');

		@include breakpoint(medium) {
			font-size: 32px;
			line-height: 37px;
		}
	}

	@include staverton() {
		font-family: $staverton-secondary-font;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.125em;
		text-transform: uppercase;
		color: get-color('pickled-blue');
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.125em;
		font-weight: 700;
	}
}


@mixin body-copy {
	@include ullensvang() {
		font-family: $ullensvang-secondary-font;
		font-size: 15px;
		line-height: 25px;
		color: get-color('dark-grey');
	}

	@include staverton() {
		font-family: $staverton-secondary-font;
		font-size: 15px;
		line-height: 25px;
		color: get-color('pickled-blue');
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 14px;
		line-height: 27px;
	}
}


@mixin price {
	@include ullensvang() {
		font-family: $ullensvang-primary-font;
		font-size: 35px;
		line-height: 32px;
		font-weight: bold;
		letter-spacing: 0.025em;
		color: get-color('dark-blue');

		/*@include breakpoint(medium) {
			font-size: 45px;
			line-height: 45px;
			letter-spacing: 0;
		}*/
	}

	@include staverton() {
		font-family: $staverton-primary-font;
		font-size: 30px;
		line-height: 45px;
		font-style: italic;
		color: get-color('golden-brown');
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 30px;
		line-height: 45px;
		font-style: italic;
		font-weight: 300;
	}
}


@mixin quote {
	@include ullensvang() {
		font-family: $ullensvang-primary-font;
		font-size: 30px;
		line-height: 42px;
		color: get-color('dark-grey');
	}

	@include staverton() {
		font-family: $staverton-secondary-font;
		font-size: 24px;
		line-height: 36px;
		font-weight: 300;
		color: get-color('pickled-blue');
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 24px;
		line-height: 36px;
		font-weight: 300;
	}
}


@mixin terms-and-conditions {
	@include ullensvang() {
		font-family: $ullensvang-secondary-font;
		font-size: 14px;
		line-height: 21px;
		letter-spacing: 0.025em;
		color: get-color('dark-grey');
	}

	@include staverton() {
		font-family: $staverton-secondary-font;
		font-size: 14px;
		line-height: 21px;
		font-weight: 300;
		letter-spacing: 0.025em;
		color: get-color('pickled-blue');
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 13px;
		line-height: 21px;
		letter-spacing: 0.025em;
	}
}


@mixin bullet-list {
	@include ullensvang() {
		font-family: $ullensvang-primary-font;
		font-size: 20px;
		line-height: 28px;
		font-weight: bold;
		color: get-color('dark-grey');
	}

	@include staverton() {
		font-family: $staverton-secondary-font;
		font-size: 15px;
		line-height: 28px;
		font-weight: 600;
		color: get-color('pickled-blue');
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 15px;
		line-height: 28px;
		font-weight: 500;
	}
}


@mixin similar-things-to-do {
	@include ullensvang() {
		font-family: $ullensvang-primary-font;
		font-size: 32px;
		line-height: 37px;
		font-weight: bold;
		text-transform: lowercase;
		color: get-color('dark-blue');
	}

	@include staverton() {
		font-family: $staverton-primary-font;
		font-size: 30px;
		line-height: 37px;
		color: get-color('pickled-blue');
	}

	@include formby() {
		font-family: $formby-font;
		font-size: 25px;
		line-height: 37px;
		letter-spacing: 0.05em;
	}
}

@mixin offer-detail-top-para {
	@include ullensvang() {
		font-family: $ullensvang-secondary-font;
		font-size: 15px;
		line-height: 25px;
		font-weight: 300;
		color: get-color('dark-grey');

		@include breakpoint(medium) {
			font-size: 22px;
			line-height: 33px;
		}
	}

	@include staverton() {
		font-family: $staverton-secondary-font;
		font-size: 18px;
		line-height: 30px;
		font-weight: 300;
		color: get-color('grey-black');

		
	}
}
