.terms {
    background-color: transparent;
    padding: 60px 20px;

    @include breakpoint(small only) {
        padding: 20px;
    }

    @include breakpoint(large) {
        padding-left: 73px;
    }

    &__heading {
        h2 {
            @include sub-headings;

            @include price;

            @include staverton() {
              text-transform: none;
              font-style: normal;
              letter-spacing: normal;
            }

            @include formby() {
                text-transform: uppercase;
                color: get-color('mid-warm-grey');
                font-size: 25px;
                line-height: 37px;
                letter-spacing: 0.05em;
                font-style: normal;
                margin-bottom: 20px;
            }
        }
    }

    &__content {
        p {
            @include body-copy;

            @include formby() {
                font-size: 13px;
                line-height: 21px;
                letter-spacing: 0.025em;
            }
        }
    }
}
