.concierge-detail {

    @include ullensvang() {
		background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 100%);
	}

    @include formby() {
        padding-top: 75px;
    }

    &__inner {

    }

    &__text {

        @include breakpoint(small only) {
            margin-bottom: 30px;
            padding: 0 10px;
        }
        
        @include breakpoint(large) {
            padding-left: 66px;
        }

        p,
        li {
            @include body-copy;
            font-weight: 300;
            
            a {
                color: inherit;
            }
        }
        
        
    }

    &__sidebar {
        margin-bottom: 13px;

        &-title {

            @include breakpoint(small only) {
                padding: 0 10px;
            }

            h3 {
                @include similar-things-to-do;
                margin-bottom: 40px;

                @include breakpoint(small only) {
                    margin-bottom: 30px;
                }

                @include formby() {
                    text-transform: uppercase;
                    color: get-color('cold-grey');
                }
            }
        }

        &-item {
            margin-bottom: 50px;
            background-color: #fff;
            padding-bottom: 30px;

            @include breakpoint(small only) {
                margin-bottom: 30px;
            }

            &-image {

            }

            &-info {
                padding: 35px 30px 13px;

                h4 {
                    @include similar-things-to-do;
                    margin-bottom: 3px;

                    @include formby() {
                        text-transform: uppercase;
                        color: get-color('cold-grey');
                    }
                    
                    a {
                        color: inherit;
                    }
                }
            }

            &-cta {
                margin-bottom: 20px;
            }
        }
    }
}