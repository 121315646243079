.menus {
	@include breakpoint(small only) {
		padding-left: 10px;
		padding-right: 10px;
	}

	@include breakpoint(medium) {
		margin-bottom: -100px;
	}

	@include ullensvang() {
		background: rgb(255,255,255);
		background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 100%);
	}

	h2 {
		@include price();
		text-transform: lowercase;
		margin-bottom: 10px;

		@include staverton() {
			text-transform: none;
			font-style: none;
			font-size: 42px;
			color: get-color('pickled-blue');
		}

		@include formby() {
			font-size: 29px;
			line-height: 43px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			font-style: none;
		}
	}

	&__logo {
		text-align: center;
		margin-bottom: 50px;

		@include breakpoint(medium) {
			margin-bottom: 50px;
		}
	}

	&__text {
		p,
		li {
			@include body-copy();
		}

		&--left {
			@include breakpoint(medium) {
				padding-right: 40px;
			}
		}

		&--right {
			@include breakpoint(medium) {
				padding-left: 40px;
			}
			
			&:last-child {
				margin-bottom: 90px;
			}
		}
	}

	&__cta {
		padding-top: 52px;
		margin-bottom: 50px;

		@include breakpoint(medium) {
			margin-bottom: 150px;
			text-align: center;
		}
	}

	&__menus-image,
	&__times-image {
		display: flex;
		overflow: hidden;

		@include breakpoint(small only) {
			max-height: 400px;
			aspect-ratio: 1 / 1;
		}

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
			display: block;
			
		}
	}

	&__menus-image {
		@include breakpoint(small only) {
			margin: 25px 0 35px;
		}

		max-height: 500px;
	}

	&__times-image {
		@include breakpoint(medium) {
			transform: translateY(-75px);
		}

		max-height: 500px;
	}

	&__list {
		@include flex-align-center();
		text-align: left;

		@include breakpoint(medium) {
			background-color: #fff;
			padding: 40px;
			transform: translateY(-75px);
		}

		@include breakpoint(large) {
			padding-right: 105px;
		}

		ul {
			margin: 0;
			list-style-type: none;
			@include quote();
			font-size: 25px !important;
			line-height: 40px !important;

			@include staverton() {
				line-height: 38px !important;
			}
		}

		a {
			display: block;

			span {
				float: right;

				@include formby() {
					font-size: 17px !important;
					font-weight: 400;
					text-transform: lowercase;
				}
			}

			&:hover span {
				font-weight: bold;
				@include staverton() {
					color: get-color('golden-brown');
				}
			}

			@include staverton() {
				color: get-color('pickled-blue');
				font-size: 17px;
			}

			@include formby() {
				font-size: 17px !important;
				font-weight: 300;
			}
		}
	}

	&__times {
		@include breakpoint(medium) {
			padding-bottom: 95px;
		}

		&-inner {
			@include flex-align-center();
			text-align: left;
			padding: 40px 0 0;

			@include breakpoint(medium) {
				background-color: #fff;
				padding: 40px;
				min-height: 100%;
			}

			@include breakpoint(large) {
				padding-left: 73px;
			}
		}

		h2 {
			@include staverton() {
				margin-bottom: 22px;
			}
		}

		p {
			@include ullensvang() {
				font-family: $ullensvang-primary-font;
				font-size: 25px;
				line-height: 35px;

				em {
					font-style: normal;
					font-size: 20px;
				}
			}

			@include staverton() {
				font-size: 15px;
				line-height: 28px;
				font-weight: 600;

				em {
					font-style: normal;
				}
			}

			@include formby() {
				font-size: 15px;
				line-ehgiht: 28px;
				font-weight: 500;
			}
		}
	}

	.times__grid {
		@include breakpoint (small only) {
			flex-direction: column-reverse;
			margin-top: 50px;
		}
	}
}
