.instant-book {
	margin-bottom: 40px;
	
	&__header {
		text-align: center;
		
		h3 {
			@include copy-headings();
		}
	}
	
	&__widget {
		p {
			font-weight: 500;
			text-transform: uppercase;
			font-size: 14px;
			letter-spacing: 0.1em;
			margin-bottom: 0;
		}
		
		.widget-box input[type="text"],
		.widget-box input[type="number"],
		.widget-box input[type="submit"],
		.widget-select-venue {
			height: 33px;
			line-height: 33px;
		}
		
		.tvd-widget-availability-btnSearch {
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			
			@include staverton() {
				font-family: $staverton-primary-font;
				letter-spacing: 0.025em;
				line-height: 19px;
				color: get-color('eggs-hell') !important;
				text-transform: capitalize !important;
				background-color: get-color('pickled-blue') !important;
				
				&:hover,
				&:active {
					background-color: get-color('golden-brown') !important;
					color: #fff !important;
				}
			}
		}
	}
}