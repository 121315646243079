.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000;
  width: 100%;
  transition: all 0.3s ease-in-out;
  opacity: 0;


  &__inner {
    @include ullensvang() {
      position: relative;
    }
  }

  @include ullensvang() {
    font-family: $ullensvang-primary-font;
    background-color: #fff;
  }

  @include staverton() {
    font-family: $staverton-primary-font;
    background-color: get-color("eggs-hell");
    padding-bottom: 20px;
  }

  @include formby() {
    font-family: $formby-font;
    background-color: get-color("light-grey");
  }

  &__open-off-canvas {
    position: absolute;
    top: 47px;
    left: 53px;
    width: 63px;
    cursor: pointer;
    @include body-copy();
    font-weight: bold;
    font-size: 18px;
    line-height: 18px !important;
    text-align: left;
    transition: all .3s ease-in-out;

    @media screen and (max-width: $off-canvas-breakpoint) {
      left: 20px;
    }

    @include ullensvang() {
      text-transform: lowercase;
      color: get-color("dark-blue");
    }

    @include staverton() {
      color: get-color("pickled-blue");
    }

    @include formby() {
      color: get-color("warm-grey");
    }

    svg {
      overflow: hidden;
    }

    &:hover {
      #burger-line-middle {
        transform: scale(1.5, 1);
      }
    }

    rect {
      transform-origin: left center;
      transition: all 0.3s ease-in-out;

      @include ullensvang() {
        fill: get-color("dark-blue");
      }

      @include staverton() {
        fill: get-color("pickled-blue");
      }

      @include formby() {
        fill: get-color("warm-grey");
      }
    }
  }

  &__lang {

    transition: all .3s ease-in-out;
  }


  &__phone {
    display: none;
    transition: all .3s ease-in-out;

    @include breakpoint (medium down) {
      display: block;
      position: absolute;
      top: 43px;
      right: 20px;

    }
  }

  &__logo {
    width: 80%;
    max-width: 136px;
    margin: 0 auto 17px;
    padding-top: 25px;
    transition: all 0.3s ease-in-out;

    @include staverton() {
      max-width: 200px;
    }

    @include formby() {
      max-width: 160px;
    }

    &--white,
    &--compact {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  &__top-nav {
    position: absolute;
    top: 44px;
    right: 230px;
    transition: all .3s ease-in-out;

    @include formby() {
      top: 43px;
      right: 200px;
      display: flex;

      @media screen and (max-width: $off-canvas-breakpoint) {
        display: none;
      }
    }

    @media screen and (max-width: $off-canvas-breakpoint) {
      display: none;
    }

    ul {
      margin: 0;
      list-style-type: none;
      display: flex;
    }

    li {
      display: inline;
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      @include body-copy();
      font-weight: bold;
      transition: color 0.3s ease-in-out;

      @include ullensvang() {
        text-transform: lowercase;
        color: get-color("dark-blue");

        &:hover,
        &:active {
          color: get-color("aqua");
        }
      }

      @include staverton() {
        color: get-color("pickled-blue");

        &:hover,
        &:active {
          color: get-color("golden-brown");
        }
      }

      @include formby() {
        color: get-color("warm-grey");

        &:hover,
        &:active {
          color: get-color("cold-grey");
        }
      }
    }
  }

  /*&__open-booking.button {
    @include formby() {
      @media screen and (max-width: $off-canvas-breakpoint) {
        position: fixed;
        top: initial !important;
        bottom: 0;
        left: 0;
        width: 100%;
        border: 0;
        padding-bottom: env(safe-area-inset-bottom) !important;
        color:#4d4946;
        background-color: #fff;
        border:none;


      }
    }
	}*/

  &__bottom-nav {
    transition: all 300ms ease-in-out;
    a:first-of-type:not(.header__open-booking) {
      display: none;
      transition: all 300ms ease-in-out;
      @media screen and (max-width: 450px) {
        width: min-content;
      }
    }

    @media screen and (max-width: $off-canvas-breakpoint) {
      position: fixed;
      top: initial !important;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-bottom: env(safe-area-inset-bottom) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      background-color: #fff;
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;


      a:first-of-type:not(.header__open-booking)  {
        display: inline-block;
        margin-right: 15px;
        font-size: 15px;

        @include staverton() {
          font-family: $staverton-secondary-font;
          font-weight: 700;
          color: get-color("pickled-blue");
        }

        @include ullensvang() {
          font-family: $ullensvang-secondary-font;
          font-weight: 700;
          text-transform: lowercase;
          color: get-color("dark-blue");
        }

        @include formby() {
          font-weight: 700;
          color: get-color("warm-grey");

        }
      }
    }
  }

  &__open-booking.button {
    position: absolute;
    top: 40px;
    right: 53px;
    margin: 0;
    padding: 6px 16px !important;


    @media screen and (max-width: $off-canvas-breakpoint) {
      position: static;
    }

    @include ullensvang() {
      border: 1px solid get-color("dark-blue");

      &:hover,
      &:active {
        background-color: #fff;
      }
    }
  }

  &__lang {
    position: absolute;
    top: 43px;
    left: 143px;

    @media screen and (max-width: $off-canvas-breakpoint) {
      left: initial;
      right: 20px;
    }

    button {
      position: relative;
      cursor: pointer;
      padding-left: 18px;
      width: 100%;
      height: 28px;
      text-align: left;

      @include ullensvang() {
        font-size: 18px;
        letter-spacing: 0.1em;
        font-weight: bold;
        text-transform: lowercase;
        color: get-color("dark-blue");

        &::after {
          content: "";
          display: inline-block;
          width: 13px;
          height: 8px;
          background: url("img/arrow-down-dark-blue.svg") no-repeat center;
        }
      }

      @include staverton() {
        font-size: 14px;
        color: get-color("pickled-blue");
        font-weight: bold;
        text-transform: lowercase;
        font-family: $staverton-secondary-font;

        &::after {
          content: "";
          display: inline-block;
          width: 13px;
          height: 8px;
          background: url("img/arrow-down-pickled-blue.svg") no-repeat center;
        }
      }
    }

    ul {
      position: absolute;
      z-index: 20000;
      top: 27px;
      right: 0;
      width: 100%;
      padding: 0;
      border: none;
      margin: 0;
      list-style-type: none;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease-in-out;

      @include ullensvang() {
        background: get-color("dark-blue");
      }

      @include staverton() {
        background-color: get-color("golden-brown");
      }
    }

    &:hover ul {
      opacity: 1;
      pointer-events: all;
    }

    a {
      color: #fff;
      padding-left: 18px;

      @include ullensvang() {
        font-size: 18px;
        letter-spacing: 0.1em;
        font-weight: bold;
        text-transform: lowercase;
        background-color: get-color("dark-blue");
      }

      @include staverton() {
        font-size: 14px;
        font-weight: bold;
        text-transform: lowercase;
        background-color: get-color("golden-brown");
      }
    }
  }

  &--visible {
    opacity: 1;
  }



  &--transparent {
    background-color: transparent !important;

    .header {
      &__open-off-canvas {
        color: #fff !important;

        rect {
          @include ullensvang() {
            fill: #fff;
          }

          @include staverton() {
            fill: #fff;
          }

          @include formby() {
            fill: #fff;
          }
        }
      }

      &__logo {
        &--colour {
          display: none;
        }

        &--white {
          display: block;
        }
      }

      &__top-nav li a,
      &__lang button {
        @include ullensvang() {
          color: #fff;
        }

        @include staverton() {
          color: #fff;
        }

        @include formby() {
          color: #fff;
        }
      }

      &__top-nav {
        @include staverton() {
          right: 230px;
        }
        @include formby() {
            right: 200px;
        }
      }

      &__lang button {
        &::after {
          background-image: url("img/arrow-down-white.svg") !important;
        }
      }

      &__bottom-nav {
        @media screen and (max-width: $off-canvas-breakpoint) {
          background-color: transparent;

          a:first-of-type:not(.header__open-booking)  {
            color: #fff !important;

            @include formby() {
              font-family: $formby-font;
              font-size: 12px;
              line-height: 19px;
              letter-spacing: 0.1em;
              font-weight: 500;
              color: #fff;
              background-color: transparent;
              border: 1px solid #fff;
              color: get-color('warm-grey');
              text-transform: uppercase;
              padding:6px 0!important;
              min-width: 125px;


              &:hover {
                background-color: get-color('warm-grey');
                color: #fff;
              }
            }

          }
        }
      }

      &__open-booking.button {
        @include formby() {
          color: #fff ;
          background-color: transparent;
          border: 1px solid #fff;

          &:hover,
          &:active {
            border-color: get-color('warm-grey');
            background-color: get-color('warm-grey');
            color: #fff;
          }
        }
      }
    }
  }

  &--compact {
    background-color: #fff !important;
    //padding-bottom: 8px;
    box-shadow: 0 5px 10px rgba(#000, 0.1);

    @include staverton() {
      padding-bottom: 0;
    }

    .header {
      &__open-off-canvas {
        top: 32px;
        color: transparent !important;
        transition: all .3s ease-in-out;
      }

      &__lang {
        top: 28px;
        transition: all .3s ease-in-out;
      }

      &__phone {

        svg {
          path {
            fill: get-color('pickled-blue');
          }
        }

        @include breakpoint (medium down) {
          top: 28px;
        }
      }

      &__top-nav {
        top: 29px;
        right: 230px;
        transition: all .3s ease-in-out;

        @include formby() {
          top: 28px;
          right: 200px;

          @media screen and (min-width: $off-canvas-breakpoint) {
            display: flex;

            .button {
              margin-bottom: 0;
              border-color: get-color('mid-warm-grey');



              &:hover {
                color: #fff;
                border-color: get-color('mid-warm-grey');
              }
            }


          }
        }
      }

      &__bottom-nav {
        a:first-of-type:not(.header__open-booking)  {


          @include formby() {
            font-family: $formby-font;
            font-size: 12px;
            line-height: 19px;
            letter-spacing: 0.1em;
            font-weight: 500;
            color: #fff;
            background-color: transparent;
            border: 1px solid get-color('mid-warm-grey');
            color: get-color('warm-grey');
            text-transform: uppercase;
            padding:6px 0!important;
            min-width: 125px;



            &:hover {
              background-color: get-color('warm-grey');
              color: #fff;
            }
          }

        }


      }

      &__open-booking.button {
        top: 25px;

        @include staverton() {
          color: #fff;
          background-color: get-color("orange");
        }
      }

      &__logo {
        &--white,
        &--colour {
          display: none;
        }

        &--compact {
          display: block;

          @include formby() {
            max-width: 174px;
          }

          @include staverton() {
            max-width: 60px;
            padding-top: 14px;
          }
        }
      }
    }
  }
}

body.theme-formby .header__top-nav .button {

  height: 33px;
  line-height: 31px;
  padding: 0px 16px 0px 16px;
  margin-top: -3px;
  font-size: 12px;
  transition: all .3s ease-in-out;

    &:hover {
      border-color: get-color('mid-warm-grey');
      color: #fff !important;
    }
}

body.theme-formby .header__bottom-nav a:first-of-type:not(.header__open-booking) {

  font-family: $formby-font;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.1em;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid get-color('mid-warm-grey');
  color: get-color('warm-grey');
  text-transform: uppercase;
  padding:6px 0!important;
  min-width: 125px;


  &:hover {
    background-color: get-color('mid-warm-grey');
    color: #fff !important;
  }

}


body.theme-formby .header--transparent .header__top-nav .button {
  border: 1px solid #fff;

  &:hover {
    border: 1px solid transparent;
  }
}
