.form {
	input[type="text"],
	input[type="email"],
	input[type="tel"] {
		border: 0;
		width: 100%;
		height: 33px;
		padding-left: 10px;
		margin-bottom: 25px;
		border-radius: 0;
		-webkit-appearance: none;

		@include ullensvang() {
			font-size: 11px;
			font-weight: 500;
			background-color: get-color('pale-aqua');
			color: get-color('dark-blue');

			@include placeholder() {
				text-transform: uppercase;
				letter-spacing: 0.1em;
				opacity: 1;
				color: get-color('dark-blue');
			}
		}

		@include staverton() {
			font-size: 11px;
			font-weight: 500;
			background-color: get-color('eggs-hell');
			color: get-color('pickled-blue');


			@include placeholder() {
				text-transform: uppercase;
				letter-spacing: 0.1em;
				opacity: 1;
				color: get-color('pickled-blue');
			}
		}

		@include formby() {
			font-size: 12px;
			background-color: #fff;
			color: get-color('warm-grey');
			letter-spacing: 0.125em;


			@include placeholder() {
				text-transform: uppercase;
				letter-spacing: 0.125em;
				opacity: 1;
				color: get-color('warm-grey');
			}
		}
	}

	button[type="submit"],
	input[type="submit"] {
		height: 33px;
		padding: 0 15px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		@include ullensvang() {
			font-family: $ullensvang-primary-font;
			text-transform: lowercase;
			font-weight: bold;
			font-size: 20px;
			letter-spacing: 0.025em;
			color: get-color('dark-blue');
			background-color: get-color('pale-aqua');

			&:hover,
			&:active {
				color: #fff;
				background-color: get-color('dark-blue');
			}
		}

		@include formby() {
			font-family: $formby-font;
			font-weight: 500;
			color: #fff;
			background-color: get-color('warm-grey');

			&:hover,
			&:active {
				color: get-color('warm-grey');
				background-color: #fff;
			}
		}
	}

	textarea {
		border: 0;
		width: 100%;
		padding-left: 10px;
		padding-top: 10px;
		margin-bottom: 25px;
		min-height: 120px;
		border-radius: 0;
		-webkit-appearance: none;

		@include ullensvang() {
			font-size: 11px;
			font-weight: 500;
			background-color: get-color('pale-aqua');
			color: get-color('dark-blue');

			@include placeholder() {
				text-transform: uppercase;
				letter-spacing: 0.1em;
				opacity: 1;
				color: get-color('dark-blue');
			}
		}

		@include staverton() {
			font-size: 11px;
			font-weight: 500;
			background-color: get-color('eggs-hell');
			color: get-color('pickled-blue');

			@include placeholder() {
				text-transform: uppercase;
				letter-spacing: 0.1em;
				opacity: 1;
				color: get-color('pickled-blue');
			}
		}

		@include formby() {
			font-size: 12px;
			background-color: #fff;
			color: get-color('warm-grey');
			letter-spacing: 0.125em;

			@include placeholder() {
				text-transform: uppercase;
				letter-spacing: 0.125em;
				opacity: 1;
				color: get-color('warm-grey');
			}
		}
	}


	&__checkbox {
		position: relative;
		text-align: left;
		margin-top: -4px;

		@include breakpoint(small only) {
			margin-bottom: 20px;
		}

		input[type="checkbox"] {
			position: absolute;
			opacity: 0;
			top: 2px;
			z-index: 1;
			width: 30px;
			height: 15px;

			~ label {
				position: relative;
				padding-left: 24px;
				padding-top: 1px;
				display: block;

				@include ullensvang() {
					padding-top: 3px;
					font-weight: 700;
					text-transform: uppercase;
					font-size: 11px;
					letter-spacing: 0.1em;
					color: get-color('dark-blue');
				}

				@include staverton() {
					text-transform: uppercase;
					font-size: 11px;
					font-weight: 500;
					letter-spacing: 0.1em;
				}

				@include formby() {
					font-size: 12px;
					letter-spacing: 0.125em;
					color: get-color('warm-grey');
				}



				&::before,
				&::after {
					content: '';
					position: absolute;
				}

				&::before {
					top: 4px;
					left: 0;
					width: 15px;
					height: 15px;

					@include ullensvang() {
						background-color: get-color('pale-aqua');
					}

					@include staverton() {
						background-color: get-color('eggs-hell');
					}

					@include formby() {
						background-color: #fff;
					}

					@include breakpoint(small only) {
						top: 0;
					}
				}

				&::after {
					content: '✓';
					color: #000;
					font-size: 12px;
					padding: 3px 0 0 1px;
					top: 0;
					left: 2px;
					width: 10px;
					height: 10px;
					opacity: 0;
					transition: opacity 0.15s ease-in-out;

					@include breakpoint(small only) {
						top: -4px;
					}
				}

				a {
					color: inherit;
					text-decoration: underline;
					transition: opacity 0.3s ease-in-out;
				}
			}

			&:checked ~ label {
				&:after {
					opacity: 1;
				}
			}
		}
	}
	
	.field-validation-error {
		display: block;
		color: red;
		font-size: 13px;
		margin: -20px 0 10px;
	}
	
	.form__checkbox .field-validation-error {
		margin: 0;
	}
}
