@font-face {
	font-family: "Yellow Peas";
	src: url("fonts/yellow-peas/7858310/4c4541f8-5fe6-40f9-ba4b-7382e5409f7a.woff2") format("woff2"),
	     url("fonts/yellow-peas/7858310/5f0fa868-996e-4e43-a403-58cad97a3516.woff") format("woff");
}

@font-face {
	font-family: "Yellow Peas";
	font-weight: bold;
	src: url("fonts/yellow-peas/7858324/7e01af1e-7cda-47bd-8605-06fb74458fe9.woff2") format("woff2"),
		 url("fonts/yellow-peas/7858310/1a9b2cb6-ee1a-4e9e-bfd2-a544c3682d04.woff") format("woff");
}