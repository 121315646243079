.grid-landing {
	padding-top: 118px;
	max-width: 1260px;
	margin: 0 auto 75px;
	
	&__item {
		position: relative;
		margin-bottom: 20px;
		
		@include breakpoint(medium) {
			&:nth-child(odd) {
				padding-right: 10px;
			}
			
			&:nth-child(even) {
				padding-left: 10px;
			}
			
			&:nth-child(odd):last-child {
				margin-left: auto;
				margin-right: auto;
				padding-right: 0 !important;
			}
		}
		
		@include breakpoint(large) {
			margin-bottom: 50px;
			
			&:nth-child(odd) {
				padding-right: 25px;
			}
			
			&:nth-child(even) {
				padding-left: 25px;
			}
		}
		
		&::before {
			z-index: 15;
		}
		
		&-inner {
			position: relative;
		}
		
		&:hover,
		&:active {
			.grid-landing__image-title span {
				transform: scale(0.75);
			}
			
			.grid-landing__content h2 {
				transform: scale(1);
			}
		}
	}
	
	&__image {
		position: absolute;
		z-index: 5;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
		
		&-title {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 20px;
			background-color: rgba(get-color('dark-blue'), 0.42);
			color: #fff;
			text-transform: uppercase;
			@include copy-headings();
			@include flex-align-center();
			
			@include ullensvang() {
				color: #fff;
			}
			
			@include staverton() {
				background-color: rgba(get-color('warm-grey'), 0.42);
				text-transform: none;
				color: #fff;
				font-size: 38px;	
			}
			
			@include formby() {
				background-color: transparent;
			}
			
			span {
				transition: transform 0.3s ease-in-out;
				
				@include formby() {
					align-self: center;
					padding: 8px 15px;
					background-color: rgba(get-color('warm-grey'), 0.42);
				}
			}
		}
	}
	
	&__content {
		position: relative;
		z-index: 10;
		min-height: 327px;
		background-color: get-color('dark-blue');
		color: #fff;
		@include flex-align-center();
		padding: 20px;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		
		@include breakpoint(medium) {
			padding: 50px;
		}
		
		@include staverton() {
			background-color: get-color('black-blue');
		}
		
		@include formby() {
			background-color: get-color('warm-grey');
		}
		
		&:hover,
		&:active {
			opacity: 1;
		}
		
		h2 {
			@include sub-headings();
			text-transform: uppercase;
			margin-bottom: 25px;
			transform: scale(0.75);
			transition: transform 0.3s ease-in-out;
			
			@include staverton() {
				color: #fff;
				font-family: $staverton-primary-font;
				text-transform: none;
				font-size: 30px;
				line-height: 37px;
				letter-spacing: 0;
			}
			
			@include ullensvang() {
				color: #fff;
				text-transform: lowercase;
			}
			
			a {
				color: inherit;
			}
		}
		
		p {
			@include body-copy();
			margin-bottom: 44px;
			
			a {
				color: inherit;
			}
			@include staverton() {
				color: #fff !important;
			}
			
			@include ullensvang() {
				color: #fff;
			}
		}
	}
	
	&__cta {
		.button {
			min-width: 174px;
			margin-bottom: 10px;
			
			@include staverton() {
				background-color: get-color('eggs-hell');
				color: get-color('warm-grey');
				
				&:hover,
				&:active {
					color: #fff;
					background-color: get-color('orange');
				}
			}
			
			@include ullensvang() {
				background-color: get-color('eggs-hell');
				color: get-color('warm-grey');
				
				&:hover,
				&:active {
					color: #fff;
					background-color: get-color('mid-aqua');
				}
			}
		}
	}
	
	&--3-column {
		padding: 0 20px;
		
		.cell {
			flex: auto;
		}
		
		.grid-landing {
			&__item {
				@include breakpoint(large) {
					margin-bottom: 30px;
					padding: 0 15px;
				}
			}
		}
	}
}