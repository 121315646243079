.footer {



	@include staverton() {
		font-family: $staverton-secondary-font;
	}

	&__newsletter {
		padding: 40px 0 50px;


		@include ullensvang() {
			background-color: get-color('aqua');
		}

		@include staverton() {
			background-color: get-color('eggs-hell');
		}

		@include formby() {
			background-color: get-color('light-grey');
			margin-top: 100px;
		}

		&-header {
			@include staverton() {
				text-align: center;
				margin-bottom: 30px;
			}


			h2 {
				@include breakpoint(small only) {
					text-align: center;
				}

				@include ullensvang() {
					color: #fff;
					font-size: 40px;
					font-family: $ullensvang-primary-font;
					margin-bottom: 8px;
				}

				@include staverton() {
					color: get-color('pickled-blue');
					font-size: 25px;
					font-family: $staverton-primary-font;
					margin-bottom: 12px;
				}

				@include formby() {
					font-family: $formby-font;
					color: get-coor('warm-grey');
					font-size: 25px;
					line-height: 37px;
					letter-spacing: 0.05em;
					text-transform: uppercase;
					margin-bottom: 12px;
				}
			}

			p {
				@include staverton() {
					max-width: 550px;
					margin: 0 auto;
				}
			}
		}

		&-cta {
			@include staverton() {
				text-align: center;
				width: fit-content;
				margin: 0 auto;
			}

			.button {
				@include staverton() {
					background-color: get-color('pickled-blue');
					color: #fff;
					padding: 0 25px;
					width: 100%;
					min-width: 150px;
					max-width: max-content;
					height: 33px;
					cursor: pointer;

					&:hover {
						background-color: get-color('orange');
					}
				}
			}
		}

		.form {

			input[type="text"],
			input[type="email"] {

				@include breakpoint(medium) {
					width: calc(100% - 25px);
				}

				@include ullensvang() {
					background-color: get-color('mid-aqua');
				}

				@include staverton() {
					color: get-color('pickled-blue');
					background-color: #fff;
				}

				@include formby() {
					color: get-color('warm-grey');
					background-color: #fff;
				}
			}

			&__checkbox input[type="checkbox"] {
				~ label {

					@include staverton() {
						font-size: 14px;
					}

					&::before {
						@include ullensvang() {
							background-color: get-color('mid-aqua');
						}

						@include staverton() {
							background-color: #fff;
						}
					}
				}
			}

			&__submit {
				@include breakpoint(medium) {

					button[type="submit"] {
						width: 100%;
						min-width: 116px;
					}
				}
			}
		}
	}

	&__main {
		color: #fff;
		padding: 80px 0 160px;

		@include breakpoint(small only) {
			text-align: center;
			padding: 50px 0 90px;
		}

		@include staverton() {


			@include breakpoint(small only) {
				.grid-x {
					margin-bottom: 80px;
				}
			}
		}

		@include ullensvang() {
			background-color: get-color('dark-blue');
		}

		@include staverton() {
			background-color: get-color('pickled-blue');
		}

		@include formby() {
			background-color: get-color('warm-grey');
		}
	}

	&__nav {
		@include breakpoint(medium) {
			@include flex-align-bottom();
		}

		@include formby() {
			@include breakpoint(medium) {
				justify-content: flex-start;
			}
		}

		@include ullensvang() {
			align-self: baseline;
		}

		@include staverton() {
			justify-content: flex-start;
		}

		@include ullensvang() {
			justify-content: flex-start;
		}

		ul {
			list-style-type: none;
			margin: 0;
		}

		li {
			line-height: 1;
			margin-bottom: 0;

			@include breakpoint(small only) {
				margin-bottom: 20px;
			}

			@include breakpoint(medium) {
				margin-top: 34px;

				&:first-child {
					margin-top: 0;
				}
			}
		}

		a {
			transition: color 0.3s ease-in-out;

			@include ullensvang() {
				color: #fff;
				font-weight: 500;
				font-size: 11px;
				letter-spacing: 0.1em;
				text-transform: uppercase;

				&:hover,
				&:active {
					color: get-color('mid-aqua');
				}
			}

			@include staverton() {
				color: #fff;
				font-weight: 500;
				font-size: 11px;
				letter-spacing: 0.1em;
				text-transform: uppercase;

				&:hover,
				&:active {
					color: #DFA585;
				}
			}

			@include formby() {
				color: #fff;
				font-weight: 500;
				font-size: 11px;
				line-height: 19px;
				letter-spacing: 0.1em;
				text-transform: uppercase;

				&:hover,
				&:active {
					color: get-color('mid-warm-grey');
				}
			}
		}
	}

	&__address {
		@include breakpoint(small only) {
			padding-top: 20px;
			padding-bottom: 40px;
		}

		@include breakpoint(medium) {
			@include flex-align-bottom();
		}

		@include breakpoint(large) {
			padding-left: 60px;
		}

		@include formby() {
			@include breakpoint(medium) {
				justify-content: flex-start;
			}
		}

		@include staverton() {
			justify-content: flex-start;
		}

		@include ullensvang() {
			justify-content: flex-start;
		}

		font-size: 12px;
		line-height: 20px;

		@include staverton(){
			line-height: 21px;
		}

		a {
			color: inherit;
		}

		p {
			margin: 0;
			transform: translateY(3px);
		}

		h4 {
			@include formby() {
				font-family: $formby-font;
			}
		}
	}

	&__social {
		@include breakpoint(small only) {
			padding-top: 40px;
		}

		@include breakpoint(medium) {
			@include flex-align-bottom();
			text-align: right;
		}

		@include staverton() {
			justify-content: flex-start;
		}

		@include ullensvang() {
			justify-content: flex-start;
		}

		@include breakpoint(medium only) {
			padding-top: 40px;
			text-align: center;
		}

		ul {
			margin: 0 0 27px;
			list-style-type: none;

			@include breakpoint(large) {
				padding-right: 5%;
			}
		}

		li {
			display: inline;
			margin-left: 20px;

			@include breakpoint(small only) {
				margin: 0 10px;
			}

			@include breakpoint(medium only) {
				&:first-of-type {
					margin-left: 0;
				}
			}
		}

		path {
			fill: #fff;
		}

		a:hover path {
			@include ullensvang() {
				fill: get-color('mid-aqua');
			}

			@include staverton() {
				fill: #DFA585;
			}

			@include formby() {
				fill: get-color('mid-warm-grey');
			}
		}
	}

	&__logo {
		text-align: center;

		@include breakpoint(small only) {
			padding-bottom: 40px;
		}

		@include breakpoint(medium only) {
			padding-top: 40px;
		}

		@include breakpoint(large) {
			padding-left: 20px;
		}

		img {
			padding-bottom: 50px;
			max-width: 144px;
			clear: both;
			display: inline-block;
		}

		a {
			clear: both;
			display: block;
			text-align: center;
		}
	}

	&__credit {
		transform: translateY(3px);

		a {
			color: inherit;
			text-transform: uppercase;
			transition: color 0.3s ease-in-out;
			font-size: 11px;
			font-weight: 500;
			letter-spacing: 0.1em;

			&:hover,
			&:active {
				@include ullensvang() {
					color: get-color('mid-aqua');
				}

				@include staverton() {
					color: #DFA585;
				}

				@include formby() {
					color: get-color('mid-warm-grey');
				}
			}
		}
	}


}

/* staverton */

.theme-staverton .footer__credit .logos {
	text-align: left;
	@include breakpoint(small only) {
		text-align: center;
	}
}

.theme-staverton .footer__credit .logos img {
	display: inline-block;
	width:30%;
	max-width: 90px;
	padding-right: 4%;
	padding-bottom: 10px;
}

.theme-staverton .footer__credit .mia {
	font-size: 12px;
	max-width: 315px;
	@include breakpoint(small only) {
		text-align: center;
		padding-bottom: 20px;
		max-width: 100%;
		width: 100%;
	}
}
.theme-staverton .footer__address {
	text-align: left;
	padding-left: 10px;
	@include breakpoint(small only) {
		text-align: center;
	}
}
